import "../style.scss";
import React from "react";
import "../mobileStyle.scss";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import { GrFormNext } from "react-icons/gr";
import { useTranslation } from "react-i18next";
import Errordiv from "../../../../widgets/alert/errordiv";
import TableSkeleton from "../../../../widgets/skeleton/table";

const ProgramPerformanceDetailsTable = ({ apiStatus, programData, filterUpdate }: any) => {
  const {t} = useTranslation();
    // Calculate starting index for the current page
    const startIndex = (filterUpdate.pageNumber) * filterUpdate.pageSize;
  
  return (
    <React.Fragment>
      <div className="table-responsive admin-table-wrapper mt-3">
        <Table responsive borderless striped className="mt-3">
          <thead>
            <tr>
              <th>{t('common.s_no')}</th>
              <th>{t('performance.program_name')}</th>
              <th>{t('performance.enrolled_users')}</th>
              <th>{t('performance.avg_grade')}</th>
              <th>{t('performance.avg_completion')}</th>
              <th>{t('common.start_date')}</th>
              <th>{t('common.end_date')}</th>
              <th>{t('performance.no_of_courses')}</th>
              <th>{t('performance.active_courses')}</th>
              <th>{t('common.view_details')}</th>
            </tr>
          </thead>
          <tbody>
            {programData.map((program, index) => (
              <tr key={program.id}>
                 <td>{startIndex + index + 1}</td>
                <td>{program.name}</td>
                <td>{program.studentCount}</td>
                {/* <td>{program.avgGrade !== null ? program.avgGrade : "-"}</td> */}
                <td>
                  {typeof program.avgGrade === "number" &&
                  !isNaN(program.avgGrade)
                    ? program.avgGrade.toFixed(2)
                    : "-"}
                </td>
                <td>
                  {program.completion !== null ? program.completion : "-"}
                </td>
                <td>{program.startDate ? program.startDate : "-"}</td>
                <td>{program.endDate !== null ? program.endDate : "-"}</td>
                <td>{program.courseCount}</td>
                <td>{program.activeCourseCount}</td>
                <td>
                  <Link
                    to={`/program-details/${program.id}/${program.name}`}
                    className="action-icons circle-icon"
                  >
                    <GrFormNext />
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        {apiStatus === "started" && programData.length === 0 && (
          <TableSkeleton numberOfRows={5} numberOfColumns={4} />
        )}
        {apiStatus === "finished" && programData.length === 0 && (
          <Errordiv msg="No record found!" cstate className="mt-3" />
        )}
      </div>
    </React.Fragment>
  );
};

export default ProgramPerformanceDetailsTable;
