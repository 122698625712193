import "../style.scss";
import "../mobileStyle.scss";
import { GrFormPrevious, GrFormNext } from "react-icons/gr";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";

const PerformanceCard = ({
  title,
  subtitle,
  children,
  handlerDecrement,
  handlerIncrement,
  totalPages,
  currentPage,
  newTitle = "",
  mostVisited = "",
}: any) => {
  return (
    <Card className={`performance-card ${mostVisited}`}>
      <div className="cardtitle d-flex justify-content-between align-items-center">
        <div>
          <h6>{title}</h6>
          <h5>{subtitle}</h5>
        </div>

        {newTitle !== "" && <div>{newTitle}</div>}

        {newTitle === "" && (
          <div className="chartnextprev-btn">
            {handlerDecrement && handlerIncrement && (
              <>
                {totalPages > 1 && (
                  <>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-decrement">Previous Page</Tooltip>
                      }
                    >
                      <button
                        className={
                          currentPage + 1 === totalPages
                            ? "active"
                            : "" || currentPage < 1
                            ? ""
                            : "active" || currentPage >= 2
                            ? "active"
                            : ""
                        }
                        onClick={handlerDecrement}
                      >
                        <GrFormPrevious />
                      </button>
                    </OverlayTrigger>
                    <span>{`${currentPage + 1}/${totalPages}`}</span>
                    <OverlayTrigger
                      placement="top"
                      overlay={
                        <Tooltip id="tooltip-increment">Next Page</Tooltip>
                      }
                    >
                      <button
                        className={
                          currentPage + 1 === totalPages ? "" : "active"
                        }
                        onClick={handlerIncrement}
                      >
                        <GrFormNext />
                      </button>
                    </OverlayTrigger>
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
      {children}
    </Card>
  );
};

export default PerformanceCard;
