import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Row, Col, Container, OverlayTrigger, Popover, Button, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import SignupForm from "./form";
import ForgotPasswordForm from "../forgotPassword";
import ResetPasswordForm from "../forgotPassword/resetPassword";
import ConfirmUserForm from "./form/confirmUser";
import logo from "../../assets/images/logo.png";
import config from "../../utils/config";
import "./style.scss";
import { useTranslation } from "react-i18next";
import languageswitch from "../../assets/images/icons/language-switch.svg";
const SignUpNew = () => {
  const [selectedValue, setSelectedValue] = useState("en");
  const location = useLocation().search;
  const [formToggle, setformtoggle] = useState<string | null>(null);
  const redirectUri = config.REDIRECT_URI;
  const { t, i18n } = useTranslation();
  const oAuthUrl = `${config.OAUTH2_URL}/authorize?response_type=code&client_id=moodle&redirect_uri=${redirectUri}&scope=openid&lang=${selectedValue}`;

  const languageList = localStorage.getItem('languages') && JSON.parse(localStorage.getItem('languages')) 

  const urlParams = new URLSearchParams(location);
  const paramLanguage: any = urlParams.get("lang") != null ? urlParams.get("lang") : "en"
  useEffect(() => {
    setformtoggle(urlParams.get("form"));
  }, [location]);

  useEffect(() => {
    localStorage.setItem("selectedLanguages", JSON.stringify(paramLanguage));
    setSelectedValue(paramLanguage);
    document.body.classList.add('authBody');
    return () => {
      document.body.classList.remove('authBody');
    };
  }, []);

  const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedValue(value);
    i18n.changeLanguage(value); // Function to switch between languages
  };

  useEffect(() => {
    localStorage.setItem("selectedLanguages", JSON.stringify(selectedValue));
    i18n.changeLanguage(selectedValue)
  }, [selectedValue])


  const LanguageDropdown = () => {
    return (
      <div className="switch-role">
        <OverlayTrigger
          trigger={["click"]}
          placement="bottom"
          overlay={
            <Popover id="popover-basic">
              <Popover.Header as="h3">{t('common.switch_language')}</Popover.Header>
              <Popover.Body>
                <select
                  className="form-select"
                  value={selectedValue}
                  // onChange={getCurrentValue}
                  onChange={(e) => selectHandler(e)}
                >
                  {Object.entries(languageList).map(
                    ([key, value]: any, index: number) => (
                      <option
                        key={index}
                        value={key}
                        data-name={value}
                      // data-priority={el.rolePriority}
                      >
                        {value}
                      </option>
                    )
                  )}
                </select>
              </Popover.Body>
            </Popover>
          }
          rootClose
        >
          <Button
            variant="link"
            className="head-icon my-lang-element"
            data-tooltip-variant="light"
            title=""
          >
            <img src={languageswitch} alt="Switch Role" />
          </Button>
        </OverlayTrigger>
        <Tooltip
          anchorSelect=".my-lang-element"
          content="Switch Languages"
          place="bottom"
          style={{ zIndex: 2 }}
        />
      </div>
    )
  }

  return (
    <React.Fragment>
      <Container fluid className="signup-container">
        <Row>
          <Col lg={7}>
            <div className="signup-leftImg">
              <span className="visually-hidden">{t('signup.login_banner')}</span>
            </div>
          </Col>
          <Col lg={5} className="d-flex align-items-center justify-content-center">
            <div className="signup-rightForm text-center">
              <LanguageDropdown />
              <div className="signup-logo rounded-circle m-auto mb-4">
                <Link to="/">
                  <img className="img-fluid" src={logo} alt="Ballistic Learning Pvt Ltd" />
                </Link>
              </div>
              <h2 className="signup-title">{t('signup.welcome')}</h2>

              {formToggle === "forgotpassword" ? (
                <React.Fragment>
                  <p className="signup-desc mb-4">{t('signup.new_password_desc')}</p>
                  <ForgotPasswordForm />
                  <p>{t('signup.go_back_to')}<a href={oAuthUrl} className="ms-1">{t('signup.sign_in')}</a></p>
                </React.Fragment>
              ) : formToggle === "resetpassword" ? (
                <React.Fragment>
                  <p className="signup-desc mb-4">{t('signup.reset_password_desc')}</p>
                  <ResetPasswordForm />
                  {/* <p>Already a member? <a href={oAuthUrl} className="ms-1">Sign in</a></p> */}
                </React.Fragment>
              ) : formToggle === "confirmuser" ?
                <React.Fragment>
                  <p className="signup-desc mb-4">{t('signup.create_your_password')}</p>
                  <ConfirmUserForm />
                </React.Fragment>
                :
                (
                  <React.Fragment>
                    <p className="signup-desc mb-4">{t('signup.sign_up_desc')}</p>
                    <SignupForm />
                    <p>{t('signup.already_member')}<a href={oAuthUrl} className="ms-1">{t('signup.sign_in')}</a></p>
                  </React.Fragment>
                )}
            </div>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
};

export default SignUpNew;
