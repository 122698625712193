import axios from "axios";
import config from "../../utils/config";

const axiosConfig = {
  axiosInstance: null,
};

const createCoreAxiosInstance = (ACCESS_TOKEN) => {
  const instance = axios.create({
    baseURL: config.CORE_SERVICE_URL,
    headers: {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
      "Content-Type": "application/json",
    },
  });

  // Add an interceptor to dynamically update the Accept-Language header
  instance.interceptors.request.use((config) => {
    const languageCode =
      localStorage.getItem("selectedLanguages") &&
      JSON.parse(localStorage.getItem("selectedLanguages"));

    // Update the Accept-Language header before the request is sent
    if (languageCode) {
      config.headers["Accept-Language"] = languageCode;
    }

    return config;
  });

  axiosConfig.axiosInstance = instance;
};

export { createCoreAxiosInstance, axiosConfig };
