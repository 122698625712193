import React from "react";
import Filter from "../../filter";
import PeersRanking from "../../peersRanking";
import { useTranslation } from "react-i18next";
import InstanceStats from "../../instanceStats";
import TopThreeGraph from "../../topThreeGraph";
import { Card, Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../../../../../../widgets/pageTitle";
import MobileHeader from "../../../../../../newHeader/mobileHeader";
import MobileFooter from "../../../../../../newFooter/mobileFooter";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import TimeSpentApiCall from "../../../../graphs/timeSpent/timeSpentApiCall";
import GradeOfCourseApiCall from "../../../../graphs/gradeOfCourse/gradeOfCourseApiCall";
import CompletionOfCourseApiCall from "../../../../graphs/completionOfCourse/completionOfCourseApiCall";
type Props = {
  commonProps: {
    programId: any;
    apiStatus: any;
    getProgramId: any;
    peerComparisonData: any;
  };
};

const Mobile = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <MobileHeader />
      <BreadcrumbComponent
        routes={[
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance (Student)", path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
          <Container fluid className="mt-3">
            <PageTitle pageTitle="Performance" gobacklink="/dashboard" />
          </Container>
          <div className="py-4">
            <Container fluid>
              <InstanceStats />
              <Filter
                programId={props.commonProps.programId}
                getProgramId={props.commonProps.getProgramId}
              />
              <Row>
                <Col lg={6} md={6} sm={12}>
                  <Card className="performance-card topThreeCard cardBoxBg">
                    <TopThreeGraph
                      apiStatus={props.commonProps.apiStatus}
                      peerComparisonData={props.commonProps.peerComparisonData}
                    />
                  </Card>
                </Col>
                <Col lg={6} md={6} sm={12}>
                  <Card className="performance-card">
                    <PeersRanking
                      apiStatus={props.commonProps.apiStatus}
                      peerComparisonData={props.commonProps.peerComparisonData}
                    />
                  </Card>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="py-4 performanceBgContainer">
            <Container fluid>
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <h4>{('performance.activity_analytics')}</h4>
              </div>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <GradeOfCourseApiCall
                    programId={props.commonProps.programId}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <CompletionOfCourseApiCall
                    programId={props.commonProps.programId}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <TimeSpentApiCall programId={props.commonProps.programId} />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>

      <MobileFooter />
    </React.Fragment>
  );
};

export default Mobile;
