

  const getTotalPagesCount = (totaldata : number) => {
    if (totaldata > customPagination.PERPAGE) {
        return Math.ceil(totaldata / customPagination.PERPAGE);
    } else {
        return 0;
    }
}

/*
 * global object for pagination values
 */
const customPagination = {
    PERPAGE: 3,
    PAGECOUNTER: getTotalPagesCount
};

export {customPagination, getTotalPagesCount }; 