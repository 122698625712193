import React from "react";
import Filter from "../../filter";
import { Container } from "react-bootstrap";
import Header from "../../../../../newHeader";
import Footer from "../../../../../newFooter";
import { useTranslation } from "react-i18next";
import HeaderTabs from "../../../../../headerTabs";
import ProgramPerformanceDetailsTable from "../../table";
import PageTitle from "../../../../../../widgets/pageTitle";
import BuildPagination from "../../../../../../widgets/pagination";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import BottomWave from "../../../../../../assets/images/background/bg-bottom.svg";
import bgLeft from "../../../../../../assets/images/background/bg-admin-left.svg";

type Props = {
  commonProps: {
    departId: any;
    apiStatus: any;
    totalpages: any;
    instituteId: any;
    handleReset: any;
    programData: any;
    filterUpdate: any;
    programStatus: any;
    departmentData: any;
    newPageRequest: any;
    getDepartmentId: any;
    getProgramStatus: any;
  };
};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Header />
      <HeaderTabs activeTab="performance" />
      <BreadcrumbComponent
        routes={[
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance", path: "/performance" },
          { name: "Program Performance Details", path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
          <Container fluid>
            <PageTitle
              pageTitle={t('performance.program_analytics')}
              gobacklink="/performance"
            />
            <Filter
            departId={props.commonProps.departId}
            apiStatus={props.commonProps.apiStatus}
            instituteId={props.commonProps.instituteId}
            handleReset={props.commonProps.handleReset}
            programStatus={props.commonProps.programStatus}
            departmentData={props.commonProps.departmentData}
            getDepartmentId={props.commonProps.getDepartmentId}
            getProgramStatus={props.commonProps.getProgramStatus}
          />
          <ProgramPerformanceDetailsTable
            apiStatus={props.commonProps.apiStatus}
            programData={props.commonProps.programData}
            filterUpdate={props.commonProps.filterUpdate}
          />
          <BuildPagination
            totalpages={props.commonProps.totalpages}
            getrequestedpage={props.commonProps.newPageRequest}
            activepage={props.commonProps.filterUpdate.pageNumber}
          />
          </Container>
        </div>
      </div>
      <Footer />
      <div className="position-relative">
        <img src={bgLeft} className="left-cicle" alt="left-cicle" />
      </div>
      <div className="bottom-bg">
        <img src={BottomWave} alt="bottom wave" />
      </div>
    </React.Fragment>
  );
};

export default Browser;
