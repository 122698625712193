
import React from "react";
import { Card } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { IoBook } from "react-icons/io5";
import { FaBuildingColumns, FaGraduationCap } from "react-icons/fa6";
import { useTranslation } from "react-i18next";

const InstanceStats = ({ performanceDetailsData }: any) => {
  const {t} = useTranslation();
  const stats = [
    {
      icon: <FaBuildingColumns />,
      value: performanceDetailsData?.instituteAndusersCount?.instituteCount || 0,
      label: t('performance.no_of_institutes')
    },
    {
      icon: <FaUsers />,
      value: performanceDetailsData?.instituteAndusersCount?.activeUserCount || 0,
      total: performanceDetailsData?.instituteAndusersCount?.totalUserCount || 0,
      label: t('performance.total_active_users'),
    },
    {
      icon: <FaGraduationCap />,
      value: performanceDetailsData?.programCount?.publishedProgramCount || 0,
      total: performanceDetailsData?.programCount?.totalProgramCount || 0,
      label: t('performance.programs_published'),
    },
    {
      icon: <IoBook />,
      value: performanceDetailsData?.courseCount?.publishedCourseCount || 0,
      total: performanceDetailsData?.courseCount?.totalCourseCount || 0,
      label: t('performance.courses_published'),
    },
  ];

  return (
    <React.Fragment>
      {/* {loading ? (
        <div className="center-loader">
          <RouterLadyLoader status={true} />
        </div>
      ) : ( */}
        <div className="d-flex flex-column gap-1">
          {stats.map((stat, index) => (
            <div key={index} className="instancestat-wrapper">
              <Card className="m-1">
                <Card.Body className="d-flex align-items-center gap-3">
                  <div className="stat-icon rounded-circle">{stat.icon}</div>
                  <div className="d-flex flex-column stat-values">
                    <div>
                      <span>{stat.value}</span>
                      {stat.total !== undefined && (
                        <span className="total"> / {stat.total}</span>
                      )}
                    </div>
                    <label>{stat.label}</label>
                  </div>
                </Card.Body>
              </Card>
            </div>
          ))}
        </div>
      {/* )
      } */}
    </React.Fragment>
  );
};

export default InstanceStats;
