
import {
    Bar,
    XAxis,
    YAxis,
    Tooltip,
    BarChart,
    Rectangle,
    CartesianGrid,
    ResponsiveContainer,
  } from "recharts";
  import "../../style.scss";
  import "../../mobileStyle.scss";
  import { useTranslation } from "react-i18next";
  import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";
  import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
  import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";
  
  // Custom Bar Shape
  const CustomBarShape = (props) => {
    const { fill, x, y, width, height } = props;
    return (
      <Rectangle
        x={x}
        y={y}
        width={width}
        height={height}
        fill={fill}
        radius={[6, 6, 0, 0]}
      />
    );
  };

  const CustomTooltip = ({ active, payload }: any) => {
    const {t} = useTranslation();
    if (active && payload) {
      const { name } = payload[0].payload;
      const CourseCompletion = payload[0].payload['Course Completion'];
      return (
        <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px', border: '1px solid #cccccc' }}>
          <p className="label" style={{color: "#337ab7"}}>{`${name}`}</p>
          <p className="intro">{`${t('performance.course_completion')}: ${CourseCompletion}%`}</p>
        </div>
      );
    }
    return null;
  };

  // Format value to percentage
const formatPercent = (value: any) => `${value}%`;
  
  // Custom XAxis Tick with Tooltip
  const CustomXAxisTick = ({ x, y, payload, completionCourse }: any) => {
    const courseName = completionCourse[payload.value];
    return (
      <OverlayTrigger
        placement="top"
        overlay={
          <BSTooltip id={`tooltip-${payload.value}`}>{courseName}</BSTooltip>
        }
      >
        <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
          {payload.value}
        </text>
      </OverlayTrigger>
    );
  };
  
  const CourseCompletion = ({ completionCourseData, error, loading }: any) => {
    const {t} = useTranslation();
  
    // Define data before using it to create completionProgram
    const data = completionCourseData.map((course: any) => {
      return {
        name: course.name,
        courseCode: course.courseCode,
        "Course Completion": course.averageComplation !== null ? course.averageComplation : 0,
      };
    });
  
    // Create a mapping of shortCode to instituteName
    const completionCourse = completionCourseData.reduce(
      (
        acc: { [key: string]: string },
        item: { courseCode: string; name: string }
      ) => {
        acc[item.courseCode] = item.name;
        return acc;
      },
      {}
    );
  
    return (
      <div className="chart-container">
        {loading ? (
          <div className="center-loader">
            <RouterLadyLoader status={true} />
          </div>
        ) : error || data.length === 0 ? (
          <div className="d-flex justify-content-center align-center">
            <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
          </div>
        ) : (
          <ResponsiveContainer width="100%" height={300}>
            <BarChart
              width={500}
              height={300}
              data={data}
              margin={{ top: 5, right: 10, left: 0, bottom: 24 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="courseCode"
                tick={(props) => (
                  <CustomXAxisTick {...props} completionCourse={completionCourse} />
                )}
                label={{
                  value: t('performance.courses'),
                  position: "insideBottom",
                  offset: -10,
                  dy: 10,
                }}
              />
             <YAxis
              ticks={[0, 25, 50, 75, 100]}  // Specify custom ticks
              tickFormatter={formatPercent}  // Format ticks as percentages
            />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="Course Completion"
                fill="#0A68C5"
                shape={<CustomBarShape />}
                barSize={22}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </div>
    );
  };
  
  export default CourseCompletion;
  
  