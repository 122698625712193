
import "../../style.scss"
import "../../mobileStyle.scss";
import { useSelector } from "react-redux";
import GradesOfCourse from "./gradeOfCourse";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { customPagination } from "../../pagination";
import PerformanceCard from "../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const GradeOfCourseApiCall = ({ programId }: any) => {
  const {t} = useTranslation();

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const [filterUpdate, setFilterUpdate] = useState({
    pageNumber: 0,
    pageSize: customPagination.PERPAGE,
  });
  const [gradeCourseData, setGradeCourseData] = useState({
    items: [],
    pages: { totalElements: 0, totalPages: 0 },
  });

  const [averageGradeData, setaverageGradeData] = useState(0)

  // call api to get program-average-grade === >>
  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${currentUserRole.id}/${programId}/course-average-grade`,
    {
      ...filterUpdate,
      ...(programId && { programId }),
    }
  );

  // Trigger the refetch when dependencies change
  useEffect(() => {
    if (programId > 0 && currentUserRole.id) {
      refetch(); 
    }
  }, [programId, currentUserRole.id, filterUpdate]);

  useEffect(() => {
    if (apiData) {
      setGradeCourseData({
        items: apiData.items || [],
        pages: apiData.pages || { totalElements: 0, totalPages: 0 },
      });
      setaverageGradeData(apiData.overallGrade != undefined ? apiData.overallGrade : 0);
    }
  }, [apiData]);

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate(prevFilterUpdate => ({
      ...prevFilterUpdate,
      pageNumber: pageRequest,
    }));
  };

  // Reset pagination when programId changes
  useEffect(() => {
    setFilterUpdate((prevFilterUpdate) => ({
      ...prevFilterUpdate,
      pageNumber: 0,
    }));
  }, [programId]);

  const handlerIncrement = () => {
    if (filterUpdate.pageNumber < gradeCourseData.pages.totalPages - 1) {
      newPageRequest(filterUpdate.pageNumber + 1);
    }
  };

  const handlerDecrement = () => {
    if (filterUpdate.pageNumber > 0) {
      newPageRequest(filterUpdate.pageNumber - 1);
    }
  };

  return (
      <PerformanceCard 
        title={t('performance.average')} 
        subtitle={t('performance.grades_of_course')}
        handlerDecrement={handlerDecrement}
        handlerIncrement={handlerIncrement}
        currentPage={filterUpdate.pageNumber}
        totalPages={gradeCourseData.pages.totalPages}
      >
        <GradesOfCourse
          error={error}
          loading={loading}
          gradeCourseData={gradeCourseData.items}
          averageGradeData={averageGradeData}
        />
      </PerformanceCard>
  );
};

export default GradeOfCourseApiCall;

