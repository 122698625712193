import "../../style.scss";
import View from "./view";
import "../../mobileStyle.scss";
import { useSelector } from "react-redux";

const ProgramCoordinatorPerformance = () => {
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const permission = useSelector(
    (state: any) => state.userAuthorities.permissions.menu
  );

  return <View instituteId={currentInstitute} permission={permission} />;
};

export default ProgramCoordinatorPerformance;
