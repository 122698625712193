import React from "react";
import { Container } from "react-bootstrap";
import CoursePerformanceTable from "../../table";
import HeaderTabs from "../../../../../headerTabs";
import PageTitle from "../../../../../../widgets/pageTitle";
import MobileFooter from "../../../../../newFooter/mobileFooter";
import MobileHeader from "../../../../../newHeader/mobileHeader";
import BreadcrumbComponent from "../../../../../../widgets/breadcrumb";
import BottomWave from "../../../../../../assets/images/background/bg-bottom.svg";
import bgLeft from "../../../../../../assets/images/background/bg-admin-left.svg";

type Props = {
  commonProps: {
    prgname: any;
    apiStatus: any;
    courseData: any;
    courseDataLength: any;
  };
};

const Browser = (props: Props) => {
  return (
    <React.Fragment>
      <MobileHeader />
      <HeaderTabs activeTab="performance" />
      <BreadcrumbComponent
        routes={[
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance", path: "/performance" },
          { name: "Program Performance Analytics", path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
          <Container fluid>
            <PageTitle
              pageTitle={`Program : ${props.commonProps.prgname}`}
              gobacklink="/performance/details"
            />
            {/* <Filter
              departId={departId}
              apiStatus={apiStatus}
              programData={programData}
              instituteId={defaultInstituteId}
              getDepartmentId={departmentHandler}
              getProgramId={getProgramIdHandler}
              departmentData={departmentData.items}
            /> */}

            <CoursePerformanceTable
              apiStatus={props.commonProps.apiStatus}
              courseData={props.commonProps.courseData}
              courseDataLength={props.commonProps.courseDataLength}
            />
          </Container>
        </div>
      </div>
      <MobileFooter />
      <div className="position-relative">
        <img src={bgLeft} className="left-cicle" alt="left-cicle" />
      </div>
      <div className="bottom-bg">
        <img src={BottomWave} alt="bottom wave" />
      </div>
    </React.Fragment>
  );
};

export default Browser;
