import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../../../../../../widgets/pageTitle";
import MobileHeader from "../../../../../../newHeader/mobileHeader";
import MobileFooter from "../../../../../../newFooter/mobileFooter";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import PopularProgramApiCall from "../../../../graphs/popularProgram/popularProgramApiCall";
import GradeOfProgramApiCall from "../../../../graphs/gradesOfProgram/gradesOfProgramApiCall";
import ActiveInactiveUserApiCall from "../../../../graphs/activeInactiveUser/activeInActiveUserApiCall";
import CompletionOfProgramApiCall from "../../../../graphs/completionOfProgram/completionOfProgramApiCall";
import MostVisitedActivityApiCall from "../../../../graphs/mostVisitedActivity/mostVisitedActivityApiCall";
import ActivityUnderProgramApiCall from "../../../../graphs/activityUnderProgram/activityUnderProgramApiCall";
import InstanceStats from "../../../../instanceStats";

type Props = {
  commonProps: {
    instituteId: any;
    permission: any;
  };
};

const Mobile = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <MobileHeader />
      <BreadcrumbComponent
        routes={!props.commonProps.permission ? [
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance (Department Head)", path: "" },
        ]: [{name: "Performance (Department Head)", path: ""}]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
          <Container fluid className="mt-3">
            <InstanceStats />
          </Container>

          <div style={{ backgroundColor: "#F3F7FA" }} className="py-4">
            <Container fluid>
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <h4>{t('performance.program_analytics')}</h4>
              </div>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <PopularProgramApiCall
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>

                <Col lg={4} md={6} sm={12}>
                  <GradeOfProgramApiCall
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>

                <Col lg={4} md={12} sm={12}>
                  <CompletionOfProgramApiCall
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-4">
                <Link to="/performance/details">
                  <Button variant="primary">{t('common.view_details')}</Button>
                </Link>
              </div>
            </Container>
          </div>

          <div className="py-4">
            <Container fluid>
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <h4>{t('performance.activity_analytics')}</h4>
              </div>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <MostVisitedActivityApiCall
                    // programId={programId}
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <ActiveInactiveUserApiCall
                    //    programId={programId}
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <ActivityUnderProgramApiCall
                    // programId={programId}
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <MobileFooter />
    </React.Fragment>
  );
};

export default Mobile;
