import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Formik, Form } from "formik";
import FieldLabel from "../../../widgets/formInputFields/labels";
import FieldTypeText from "../../../widgets/formInputFields/formTextField";
import FieldErrorMessage from "../../../widgets/formInputFields/errorMessage";
import FieldTypeSelect from "../../../widgets/formInputFields/formSelectField";
import CountryList from "../../../globals/country";
import { postData } from "../../../adapters/coreservices";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import googleReCaptcha from "../../../utils/recaptcha";
import Errordiv from "../../../widgets/alert/errordiv";
import { useNavigate } from "react-router-dom";
import config from "../../../utils/config";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { useTranslation } from 'react-i18next';

const SignupForm = () => {
  const { t } = useTranslation();
  const redirectUri = config.REDIRECT_URI;
  const oAuthUrl = `${config.OAUTH2_URL}/authorize?response_type=code&client_id=moodle&redirect_uri=${redirectUri}&scope=openid`;
  const navigate = useNavigate();
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ message: "", alertBoxColor: "" });
  const [instituteIdSelection, setinstituteIdSelection] = useState({ instituteIds: [] });
  const [instituteList, setInstituteList] = useState([{ name: t("signup.selectInstitute"), id: 0 }]);

  const initialValues = {
    lastName: "",
    firstName: "",
    email: "",
    country: "",
    instituteIds: "",
    recaptcha: "",
  };

  const userFormSchema = Yup.object({
    email: Yup.string().email().required(t("signup.requiredField")),
    firstName: Yup.string()
      .min(1)
      .test("signup.character-allowed", t("signup.onlyCharactersAllowed"), value => /^[A-Za-z0-9]+$/.test(value))
      .trim()
      .required(t("signup.requiredField")),
    lastName: Yup.string()
      .min(1)
      .test("signup.character-allowed", t("signup.onlyCharactersAllowed"), value => /^[A-Za-z0-9]+$/.test(value))
      .trim()
      .required(t("signup.requiredField")),
    country: Yup.string()
      .required(t('signup.requiredField'))
      .notOneOf(['0'], t('requiredField')),  
    instituteIds: Yup.array()
      .test('at-least-one-selected', t('atLeastOneInstitute'), () => instituteIdSelection.instituteIds.length > 0),
    recaptcha: Yup.string().required(t("signup.captchaRequired")),
  });

  function handleInstituteSelect(selectedList) {
    const selectedInstitute = selectedList.map(item => item.id);
    setinstituteIdSelection(prevState => ({
      ...prevState,
      instituteIds: selectedInstitute,
    }));
  }

  function handleInstituteRemove(selectedList) {
    const selectedInstitute = selectedList.map(item => item.id);
    setinstituteIdSelection(prevState => ({
      ...prevState,
      instituteIds: selectedInstitute,
    }));
  }

  const fetchInstitutes = async () => {
    try {
      const response = await axios.get(`https://api.microlearning.ballisticlearning.com/learning-service/api/v1/public/institutes?pageNumber=0&pageSize=30`); 
      const instituteData = response.data.items;
      const formattedData = instituteData.map(item => (item.locked === true ? { name: item.name, id: item.id } : null)).filter(Boolean);
      setInstituteList(formattedData);
    } catch (error) {
      console.error("Error fetching institutes:", error);
    }
  };

  useEffect(() => {
    fetchInstitutes();
  }, []);

  const handleFormData = (values, { setSubmitting, resetForm, setFieldError }) => {
    values.idnumber = 98789871;
    values.city = "Delhi";
    values.instituteIds = instituteIdSelection.instituteIds;

    if (values.recaptcha !== "") {
      postData("/public/signup", values, undefined, false)
        .then(res => {
          if (res.status === 201 || res.status === 200) {
            setAlertStatus(true);
            setAlertMsg({
              message: t("signup.signUpSuccess"),
              alertBoxColor: "",
            });
            setTimeout(() => {
              navigate("/");
            }, 3000);
          } else {
            setAlertStatus(true);
            setAlertMsg({
              message: t("signup.signUpError"),
              alertBoxColor: "alert-warning",
            });
          }
        })
        .catch(err => {
          if (err.response.status === 404) {
            setAlertStatus(true);
            setAlertMsg({
              message: t("signup.userExists"),
              alertBoxColor: "alert-warning",
            });
          } else {
            setAlertStatus(true);
            setAlertMsg({
              message: t("signup.signUpError"),
              alertBoxColor: "alert-danger",
            });
          }
        });
    }
  };

  return (
    <React.Fragment>
      {alertStatus === true && (
        <Errordiv
          msg={alertMsg.message}
          cstate
          className="mt-3"
          alertColor={alertMsg.alertBoxColor}
        />
      )}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={userFormSchema}
        onSubmit={(values, action) => handleFormData(values, action)}
      >
        {({
          errors,
          touched,
          isSubmitting,
          setValues,
          values,
          setFieldValue,
        }) => (
          <Form className="row">
            <div className="col-lg-6 mb-3 text-start">
              <FieldLabel
                htmlFor="firstName"
                labelText={t("common.first_name")}
                required="required"
                className="form-label"
                star="*"
              />
              <FieldTypeText name="firstName" placeholder={t("common.first_name")} />
              <FieldErrorMessage
                errors={errors.firstName}
                touched={touched.firstName}
                msgText={t("signup.requiredField")}
              />
            </div>

            <div className="col-lg-6 mb-3 text-start">
              <FieldLabel
                htmlFor="lastName"
                labelText={t("common.last_name")}
                required="required"
                className="form-label"
                star="*"
              />
              <FieldTypeText name="lastName" placeholder={t("common.last_name")} />
              <FieldErrorMessage
                errors={errors.lastName}
                touched={touched.lastName}
                msgText={t("signup.requiredField")}
              />
            </div>

            <div className="col-lg-6 mb-4 text-start">
              <FieldLabel
                htmlFor="email"
                labelText={t("common.email")}
                required="required"
                className="form-label"
                star="*"
              />
              <FieldTypeText name="email" placeholder={t("common.email")} />
              <FieldErrorMessage
                errors={errors.email}
                touched={touched.email}
                msgText={t("signup.requiredField")}
              />
            </div>

            <div className="col-lg-6 mb-4 text-start">
              <FieldLabel
                htmlFor="country"
                labelText={t("common.country")}
                required="required"
                className="form-label"
                star="*"
              />
              <FieldTypeSelect
                name="country"
                options={CountryList}
                setcurrentvalue={setValues}
                currentformvalue={values}
              />
              <FieldErrorMessage
                errors={errors.country}
                touched={touched.country}
                msgText={t("signup.requiredField")}
              />
            </div>

            <div className="col-lg-6 mb-4 text-start">
              <FieldLabel
                htmlFor="instituteIds"
                labelText={t("common.institutes")}
                required="required"
                className="form-label"
                star="*"
              />
              <Multiselect
                className="programmultiselect signup-institute"
                displayValue="name"
                placeholder={t("common.selectInstitute")}
                options={instituteList && instituteList.map(institute => ({
                  id: institute.id,
                  name: institute.name,
                }))}
                onSelect={handleInstituteSelect}
                onRemove={handleInstituteRemove}
              />
              <FieldErrorMessage
                errors={errors.instituteIds}
                touched={touched.instituteIds}
                msgText={t("signup.atLeastOneInstitute")}
              />
            </div>

            <div className="mb-4">
              <ReCAPTCHA
                sitekey={googleReCaptcha.SITE_KEY}
                onChange={value => setFieldValue("recaptcha", value)}
              />
              <FieldErrorMessage
                errors={errors.recaptcha}
                touched={touched.recaptcha}
                msgText={t("signup.captchaRequired")}
              />
            </div>

            <div className="col-12 mb-4 d-grid">
              <Button type="submit" variant="primary">
                {t("signup.sign_up")}
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default SignupForm;
