import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  programId: any;
  instituteId: any;
  getProgramId: any;
};

const View = (props: Props) => {
  const commonProps = {
    programId: props.programId,
    instituteId: props.instituteId,
    getProgramId: props.getProgramId,
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;
