import "../../../style.scss";
import "../../../mobileStyle.scss";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import ActivityCompleted from "./activityCompleted";
import PerformanceCard from "../../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../../features/customHooks/apiGetMethodHook";

const ActivityCompletedApiCall = ({ programId, instituteId }: any) => {
  const {t} = useTranslation();
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const {
    apiData = [],
    loading,
    error,
    refetch,
  } = useApiGetMethodHook<any>(
    `/performance/${instituteId}/${currentUserRole.id}/course-completion-activity`,
    {
      ...(programId && { programId }),
    }
  );

  // Trigger the refetch when dependencies change
  useEffect(() => {
    if (instituteId > 0 && currentUserRole.id > 0 && programId > 0) {
      refetch();
    }
  }, [programId, instituteId, currentUserRole.id]);

  return (
    <PerformanceCard  
      title={t('performance.average')}  
      subtitle={t('performance.activity_completed')} >
      <ActivityCompleted
        error={error}
        loading={loading}
        activityData={apiData}
      />
    </PerformanceCard>
  );
};

export default ActivityCompletedApiCall;
