import "../../style.scss";
import View from "./view";
import "../../mobileStyle.scss";
import React, { useEffect, useState } from "react";
import { getData, postData } from "../../../../../adapters/microservices";

const StudentPerformance = () => {
  const [prgId, setPrgId] = useState<number>(0);
  const [apiStatus, setApiStatus] = useState("");
  const [userProfilePic, setUserProfilePic] = useState<any[]>([]);
  const [filesIds, setFilesIds] = useState<Array<{ id: number }>>([]);
  const [peerComparisonData, setPeerComparisonData] = useState<any>([]);
  const [profilePic, setProfilePic] = useState<Array<{ id: number; url: string }>>([]);
  // get program id Handler
  const getProgramIdHandler = (id: number) => {
    setPrgId(id);
  };

  useEffect(() => {
    setApiStatus("started");
    if (prgId > 0) {
      getData(`/performance/student/${prgId}/peer-comparison`, {})
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setPeerComparisonData(result.data);
          }
          setApiStatus("finished");
        })
        .catch((err: any) => {
          console.log(err);
          setApiStatus("finished");
          setUserProfilePic([]);
        });
    }
  }, [prgId]);

  useEffect(() => {
    if (peerComparisonData.length > 0) {
      const ids = peerComparisonData
        .filter((data: any) => data.fileId !== null)
        .map((data: any) => ({ id: data.fileId as number }));
      setFilesIds(ids);
    }
  }, [peerComparisonData]);

  useEffect(() => {
    if (filesIds.length > 0) {
      postData(`/files`, filesIds)
        .then((result: any) => {
          if (result.data && result.status === 200) {
            setProfilePic(result.data);
          }
        })
        .catch((err: any) => {
          console.error("Error fetching profile pictures:", err);
        });
    }
  }, [filesIds]);

  useEffect(() => {
    const profilePicMap = profilePic.reduce((map, pic) => {
      map[pic.id] = pic.url;
      return map;
    }, {} as Record<number, string>);

    const mergedData = peerComparisonData.map((peer: any) => ({
      ...peer,
      profilePicUrl: peer.fileId !== null ? profilePicMap[peer.fileId] : null,
    }));

    setUserProfilePic(mergedData);
  }, [profilePic, peerComparisonData]);

  return (
    <React.Fragment>
      <View
        programId={prgId}
        apiStatus={apiStatus}
        getProgramId={getProgramIdHandler}
        peerComparisonData={userProfilePic}
      />
    </React.Fragment>
  );
};

export default StudentPerformance;
