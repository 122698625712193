import {
  Sector,
  RadialBar,
  RadialBarChart,
  ResponsiveContainer,
} from "recharts";
import React from "react";
import { useTranslation } from "react-i18next";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";
import { isMobile } from "react-device-detect";

const colors = ["#7DAFE0", "#458CD3", "#0A68C5"];

const renderCustomShape = (props: any) => {
  const { cx, cy, startAngle, endAngle, innerRadius, outerRadius, fill, uv } =
    props;
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={uv !== 100.01 ?  startAngle: 0}
      endAngle={uv !== 100.01 ? endAngle: 360}
      fill={fill}
      cornerRadius={10}
    />
  );
};

const GradesOfCourse = ({ gradeCourseData = [], error, loading, averageGradeData }: any) => {
  const {t} = useTranslation();
  const data = gradeCourseData.map((course: any, index: number) => {
    // const avgGrade = Number(program.grade);
    return {
      name: course.name,
      // uv: !isNaN(avgGrade) ? Math.min(avgGrade, 100) : 0, // Ensure uv does not exceed 100
      uv: course.averageGrade !== null ? Math.min(course.averageGrade, 100) : 0,
      pv: 2400,
      fill: colors[index % colors.length],
    };
  });


  if (gradeCourseData.length > 0)
    data.push({
      uv: 100.01,
      fill: "#FFFFFF"
    })  

  return (
    <>
      {loading ? (
        <div className="center-loader">
          <RouterLadyLoader status={true} />
        </div>
      ) : error || data.length === 0 ? (
        <div className="d-flex justify-content-center align-center">
          <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
        </div>
      ) : (
        <div className="averagegrades-wrapper">
          <div className="averagegrades-legend">
            <div className="mb-3">
              <div className="legend-label">{t('performance.overallAverageGrade')}</div>
              <b className="average-grades">
                {gradeCourseData.length > 0 && averageGradeData + "%"}
              </b>
            </div>
            {data.map((entry: any, index: number) => (
              entry.uv !== 100.01 &&
              <div
                key={index}
                className="d-flex mb-2 gap-3 align-items-center"
              >
                <div
                  className="p-1 rounded-circle grade-color"
                  style={{ backgroundColor: entry.fill }}
                ></div>
                <label className="programname">{entry.name}</label>
                <div className="percentage">{entry.uv}%</div>
              </div>
            ))}
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <RadialBarChart
              cx={isMobile?"50%":"30%"}
              cy="50%"
              innerRadius="40%"
              outerRadius="100%"
              barSize={10}
              data={data}
            >
              <RadialBar
                minAngle={15}
                background
                clockWise
                dataKey="uv"
                shape={renderCustomShape}
                // Set domain to ensure uv values do not exceed 100
                domain={[0, 100]}
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default GradesOfCourse;
