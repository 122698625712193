import {
  Line,
  XAxis,
  YAxis,
  Tooltip,
  LineChart,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from "react-i18next";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";

const CustomTooltip = ({ active, payload }: any) => {
  const {t} = useTranslation();
  if (active && payload) {
    const { name } = payload[0].payload;
    const numberOfActivity = payload[0].payload["Number of Activity"];
    return (
      <div
        className="custom-tooltip"
        style={{
          backgroundColor: "#ffffff",
          padding: "10px",
          border: "1px solid #cccccc",
        }}
      >
        <p className="label" style={{ color: "#337ab7" }}>{`${name}`}</p>
        <p className="intro">{`${t('performance.no_of_activity')}: ${numberOfActivity}`}</p>
      </div>
    );
  }
  return null;
};

const ActivityUnderProgram = ({
  programAcitivityData,
  error,
  loading,
}: any) => {
  const {t} = useTranslation();
  const data = programAcitivityData.map((program: any) => {
    return {
      name: program.name,
      programCode: program.programCode,
      "Number of Activity": program.activityCount,
    };
  });

  return (
    <>
      {loading && programAcitivityData.length === 0 ? (
        <div className="center-loader">
          <RouterLadyLoader status={true} />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          {error || data.length !== 0 ? (
            <LineChart
              data={data}
              margin={{ top: 10, right: 10, left: 10, bottom: 24 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="programCode"
                label={{
                  value: t('performance.program'),
                  position: "insideBottom",
                  offset: -10,
                  dy: 10,
                }}
              />
              <YAxis
                label={{
                  value: t('performance.no_of_activity'),
                  angle: -90,
                  position: "insideLeft",
                  offset: 0,
                  dy: 60,
                  dx: -2,
                }}
                tickFormatter={(tick) => `${tick}`}
              />
              {/* <Tooltip formatter={(value) => `${value}`} /> */}
              <Tooltip content={<CustomTooltip />} />
              <Line
                type="linear"
                dataKey="Number of Activity"
                stroke="#0254d8"
                strokeWidth={2}
              />
            </LineChart>
          ) : (
            <div className="d-flex justify-content-center align-center">
              <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
            </div>
          )}
        </ResponsiveContainer>
      )}
    </>
  );
};

export default ActivityUnderProgram;
