
import "../../style.scss";
import "../../mobileStyle.scss";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import  NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";

const PopularProgram = ({ proData = [], loading, error }: any) => {
  const {t} = useTranslation();
  return (
    <div className="popularprogram-table">
      {loading ? (
          <div className="center-loader">
            <RouterLadyLoader status={true} />
          </div>
        ) : error || proData.length === 0 ? (
          <div className="d-flex justify-content-center align-center">
            <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
          </div>
        ) : (
        <Table borderless responsive striped className="text-center">
          <thead>
            <tr>
              <th>{t('performance.rank')}</th>
              <th>{t('performance.program_name')}</th>
              <th>{t('performance.enrolments')}</th>
              <th>{t('performance.visits')}</th>
              <th>{t('performance.completions')}</th>
            </tr>
          </thead>
          <tbody>
            {proData
              .sort((a: any, b: any) => b.visits - a.visits)
              .map((program: any, index: any) => (
                <tr
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f1f8ff" : "#ffffff",
                  }}
                >
                  <td>{index + 1}</td>
                  <td>{program.name}</td>
                  <td>{program.studentCount}</td>
                  <td>{program.visitsCount}</td>
                  <td>{program.completion !== null ? program.completion : 0}%</td>
                </tr>
              ))}
          </tbody>
        </Table>
      )}
    </div>
  );
};

export default PopularProgram;




