import React from "react";
import { Modal } from "react-bootstrap";
import { IoBook } from "react-icons/io5";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { FaGraduationCap } from "react-icons/fa";
import WaveBottom from "../../../../../assets/images/background/bg-modal.svg";

interface IProps {
  show: boolean;
  onHide: () => void;
  allBadges: any[];
  allCertificates: any[];
  label: string;
}

const BadgesModal: React.FunctionComponent<IProps> = ({
  show,
  onHide,
  allBadges,
  allCertificates,
  label,
}: IProps) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      {label !== undefined && label !== "Certificates" ? (
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-design-wrapper"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
              {t('performance.my_badges')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {allBadges.map((badges: any, index: number) => (
                <Col md={6}>
                  <div key={index} className="badges-list">
                    <img
                      src={badges.badgeUrl}
                      alt={badges.badgeName}
                      className="mr-3"
                    />
                    <h6>{badges.badgeName}</h6>
                    <div className="badgesInfo">
                      <span className="stat-icon rounded-circle">
                        <IoBook />
                      </span>
                      {badges.courseName}
                    </div>
                    <div className="badgesInfo">
                      <span className="stat-icon rounded-circle">
                        <FaGraduationCap />
                      </span>
                      {badges.programName}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <img src={WaveBottom} alt="WaveBottom" className="wavebg" />
        </Modal>
      ) : (
        <Modal
          show={show}
          onHide={onHide}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          className="modal-design-wrapper"
        >
          <Modal.Header closeButton>
            <Modal.Title id="contained-modal-title-vcenter">
            {t('performance.my_certificates')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {allCertificates.map((certificates: any, index: number) => (
                <Col md={6}>
                  <div key={index} className="badges-list">
                    {/* <img src={certificates.badgeUrl} alt={certificates.badgeName} className="mr-3" /> */}
                    <h6><a href={certificates.certificateUrl} target="_blank">{certificates.certificateName}</a></h6>
                    <div>{certificates.issueDate}</div>
                    <div className="badgesInfo">
                      <span className="stat-icon rounded-circle">
                        <IoBook />
                      </span>
                      {certificates.courseName}
                    </div>
                    <div className="badgesInfo">
                      <span className="stat-icon rounded-circle">
                        <FaGraduationCap />
                      </span>
                      {certificates.programName}
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Modal.Body>
          <img src={WaveBottom} alt="WaveBottom" className="wavebg" />
        </Modal>
      )}
    </React.Fragment>
  );
};

export default BadgesModal;
