import "../../style.scss";
import View from "./view";
import "../../mobileStyle.scss";
import React, { useState } from "react";
import { useSelector } from "react-redux";

const TeacherPerformance = () => {
  const [prgId, setPrgId] = useState(0);

  // get program status Handler
  const getProgramIdHandler = (id: number) => {
    setPrgId(id);
  };

  // Get current institute id
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  return (
      <React.Fragment>
      <View
        programId={prgId}
        instituteId={currentInstitute}
        getProgramId={getProgramIdHandler}
      />
    </React.Fragment>
  );
};

export default TeacherPerformance;
