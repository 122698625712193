import "../style.scss";
import React from "react";
import "../mobileStyle.scss";
import { useTranslation } from "react-i18next";
import { Container, Row, Col, Button } from "react-bootstrap";
import { FiltersLoadingBtn } from "../../../../utils/filtersLoading";

const Filter = ({
  apiStatus,
  departId,
  instituteId,
  handleReset,
  programStatus,
  departmentData,
  getDepartmentId,
  getProgramStatus,
}: any) => {

  const {t} = useTranslation();

  //  Get current Department id
  const departmentHandler = (e: any) => {
    const depId = parseInt(e.target.value);
    getDepartmentId(depId);
  };

  const statusHandlerChange = (e: any) => {
    getProgramStatus(e.target.value.toLowerCase());
  };

  return (
    <React.Fragment>
      <div className="filter-wrapper mt-2 me gap-3">
        <Container fluid>
          <Row className="g-2">
            <Col className="col-auto">
              <label>{t('performance.department')}</label>
              <select
                className="form-select"
                name="department"
                onChange={departmentHandler}
                value={departId}
                disabled={instituteId == 0}
              >
                <option value={0}>{t('performance.all_department')}</option>
                {departmentData.map((option: any) => 
                   option.published && (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="col-auto">
              <label>{t('performance.program-status')}</label>
              <select
                className="form-select"
                name="status"
                onChange={statusHandlerChange}
                value={programStatus}
              >
                <option value="">{t('common.all')}</option>
                <option value="active">{t('performance.active')}</option>
                <option value="completed">{t('performance.completed')}</option>
              </select>
            </Col>
            <Col>
              <div className="d-flex align-items-end h-100">
                {FiltersLoadingBtn(apiStatus)}
                <Button
                  variant="outline-secondary"
                  type="reset"
                  onClick={handleReset}
                >
                  {t('common.reset')}
                </Button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Filter;
