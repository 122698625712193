
import {
  RadialBarChart,
  RadialBar,
  ResponsiveContainer,
  Sector,
} from "recharts";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";
import { isMobile } from "react-device-detect";

const colors = ["#7DAFE0", "#458CD3", "#0A68C5"];

const renderCustomShape = (props:any) => {
  const { cx, cy, startAngle, endAngle, innerRadius, outerRadius, fill, grade} =
  props;
  return (
    <Sector
      cx={cx}
      cy={cy}
      innerRadius={innerRadius}
      outerRadius={outerRadius}
      startAngle={grade !== 100.01 ? startAngle:0}
      endAngle={grade !== 100.01 ? endAngle : 360}
      fill={fill}
      cornerRadius={10}
    />)
};

const GradesOfProgram = ({ gradeProgramData = [], error, loading, averageGradeData }: any) => {
  const {t} = useTranslation();
  const data = gradeProgramData.map((program: any, index: number) => {
    // const avgGrade = Number(program.grade);
    return {
      name: program.name,
      grade: program.grade !== null ? Math.min(program.grade, 100) : 0,
      fill: colors[index % colors.length],
    };
  });

  
    if (gradeProgramData.length > 0)
    data.push({
      grade: 100.01,
      fill: "#FFFFFF"
    })  
  
  return (
    <>
      {loading ? (
        <div className="center-loader">
          <RouterLadyLoader status={true} />
        </div>
      ) : error || data.length === 0 ? (
        <div className="d-flex justify-content-center align-center">
          <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
        </div>
      ) : (
        <div className="averagegrades-wrapper">
          <div className="averagegrades-legend">
            <div className="mb-4">
              <div className="legend-label">{t('performance.overallAverageGrade')}</div>
              <b className="average-grades">
                { gradeProgramData.length > 0 && averageGradeData + "%"}
              </b>
            </div>
            {data.map((entry: any, index: number) => (
              entry.grade != 100.01 &&
              <div key={index} className="d-flex mb-2 gap-2 align-items-center">
                <div
                  className="p-1 rounded-circle grade-color"
                  style={{ backgroundColor: entry.fill }}
                ></div>
                <label className="programname">{entry.name}</label>
                <div className="percentage">{entry.grade.toFixed(2)}%</div>
              </div>
            ))}
          </div>
          <ResponsiveContainer width="100%" height={300}>
            <RadialBarChart
              cx={isMobile?"50%":"30%"}
              cy="50%"
              innerRadius="40%"
              outerRadius="100%"
              barSize={10}
              data={data}
            >
              <RadialBar
                minAngle={15}
                background
                clockWise
                dataKey="grade"
                shape={renderCustomShape}
                // Set domain to ensure uv values do not exceed 100
                domain={[0, 100]}
              />
            </RadialBarChart>
          </ResponsiveContainer>
        </div>
      )}
    </>
  );
};

export default GradesOfProgram;
