import { Button } from "react-bootstrap";
import { LoadingButton } from "../../widgets/formInputFields/buttons";
import { useTranslation } from "react-i18next";

export const FiltersLoadingBtn = (apiStatus, type = "submit") => {
  const {t}= useTranslation()
  return (
    <>
      <Button variant="primary" type={type} className="me-2">
        {apiStatus !== "finished" ? (
          <LoadingButton status="filterLoader" />
        ) : (
         t('common.filter')
        )}
      </Button>
    </>
  );
};
