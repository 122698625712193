import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";
import NoDataFoundImg from "../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../globals/globalLazyLoader/routerLadyLoader";

const CustomBarShape = (props: any) => {
  const { fill, x, y, width, height } = props;
  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  const {t} = useTranslation();
  if (active && payload && payload.length) {
    const { instituteName } = payload[0].payload;
    const numberOfPrograms = payload[0].payload['Number of programs'];

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px', border: '1px solid #cccccc' }}>
        <p className="label" style={{ color: "#337ab7" }}>{`${instituteName}`}</p>
        <p className="intro">{`${t('performance.no_of_programs')}: ${numberOfPrograms}`}</p>
      </div>
    );
  }
  return null;
};

const CustomXAxisTick = ({ x, y, payload, instituteNames }: any) => {
  const institute = instituteNames[payload.value];
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <BSTooltip id={`tooltip-${payload.value}`}>{institute}</BSTooltip>
      }
    >
      <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </OverlayTrigger>
  );
};

const InstituteBarchart = ({ data, loading, error }: any) => {
  const { t } = useTranslation();

  // Create a mapping of shortCode to instituteName
  const instituteNames = data.reduce(
    (
      acc: { [key: string]: string },
      item: { shortCode: string; instituteName: string }
    ) => {
      acc[item.shortCode] = item.instituteName;
      return acc;
    },
    {}
  );

  const instituteData = data.map((userData: any) => {
    return {
      instituteName: userData.instituteName,
      shortCode: userData.shortCode,
      "Number of programs": userData.totalProgramCount,
    };
  });

  return (
    <div className="chart-container">
      <ResponsiveContainer width="100%" height={300}>
      {loading ? (
          <div className="center-loader">
            <RouterLadyLoader status={true} />
          </div>
        ) : error || instituteData.length === 0 ? (
          <div className="d-flex justify-content-center align-center">
            <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
          </div>
        ) : (
            <BarChart
              width={500}
              height={300}
              data={instituteData}
              margin={{ top: 5, right: 10, left: 20, bottom: 20 }}
            >
              <CartesianGrid strokeDasharray="3 3" vertical={false} />
              <XAxis
                dataKey="shortCode"
                tick={(props) => (
                  <CustomXAxisTick {...props} instituteNames={instituteNames} />
                )}
                label={{
                  value: t('performance.institutes'),
                  position: "insideBottom",
                  offset: -10,
                  dy: 10,
                }}
              />
              <YAxis
                label={{
                  value: t('performance.no_of_programs'),
                  angle: -90,
                  position: "insideLeft",
                  dy: 50,
                  offset: -6,
                  dx: -2,
                }}
              />
              <Tooltip content={<CustomTooltip />} />
              <Bar
                dataKey="Number of programs"
                fill="#0A68C5"
                shape={<CustomBarShape />}
                barSize={30}
                activeBar={<Rectangle />}
              />
            </BarChart>
          )}
      </ResponsiveContainer>
    </div>
  );
};

export default InstituteBarchart;

