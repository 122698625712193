
import "../../style.scss";
import "../../mobileStyle.scss";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { customPagination } from "../../pagination";
import GradesOfProgram from "../gradesOfProgram/gradesOfProgram";
import React, { useEffect, useState } from "react";
import PerformanceCard from "../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const GradeOfProgramApiCall = ({ departmentId, status, instituteId }: any) => {

  const {t} = useTranslation();

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const [filterUpdate, setFilterUpdate] = useState({
    pageNumber: 0,
    pageSize: customPagination.PERPAGE,
  });

  const [gradeProgramData, setGradeProgramData] = useState({
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  });

  const [averageGradeData, setaverageGradeData] = useState(0)

  // call api to get program-average-grade === >>
  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${currentUserRole.id}/${instituteId}/program-average-grade`,
    {
      ...filterUpdate,
      ...(status && { status }),
      ...(departmentId && { departmentId }),
    }
  );

  // Trigger the refetch when dependencies change
  useEffect(() => {
    if (instituteId > 0 && currentUserRole.id > 0) {
      refetch(); 
    }
  }, [departmentId, status, instituteId, currentUserRole.id, filterUpdate.pageNumber]);

  useEffect(() => {
    if (apiData) {
      setGradeProgramData({
        items: apiData.items || [],
        pager: apiData.pager || { totalElements: 0, totalPages: 0 },
      });
      setaverageGradeData(apiData.avgGrade != undefined ? apiData.avgGrade : 0);
    }
  }, [apiData]);

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate(prevFilterUpdate => ({
      ...prevFilterUpdate,
      pageNumber: pageRequest,
    }));
  };

 // Reset pagination when departmentId changes
  useEffect(() => {
    setFilterUpdate(prevFilterUpdate => ({
      ...prevFilterUpdate,
      pageNumber: 0,
    }));
  }, [departmentId]);

  const handlerIncrement = () => {
    if (filterUpdate.pageNumber < gradeProgramData.pager.totalPages - 1) {
      newPageRequest(filterUpdate.pageNumber + 1);
    }
  };

  const handlerDecrement = () => {
    if (filterUpdate.pageNumber > 0) {
      newPageRequest(filterUpdate.pageNumber - 1);
    }
  };

  return (
    <React.Fragment>
      <PerformanceCard
        title={t('performance.average')}
        subtitle={t('performance.grades_of_program')}
        handlerDecrement={handlerDecrement}
        handlerIncrement={handlerIncrement}
        currentPage={filterUpdate.pageNumber}
        totalPages={gradeProgramData.pager.totalPages}
      >
        <GradesOfProgram
          error={error}
          loading={loading}
          averageGradeData={averageGradeData}
          gradeProgramData={gradeProgramData.items}
        />
      </PerformanceCard>
    </React.Fragment>
  );
};

export default GradeOfProgramApiCall;

