import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  departId: any;
  apiStatus: any;
  totalpages: any;
  instituteId: any;
  handleReset: any;
  programData: any;
  filterUpdate: any;
  programStatus: any;
  departmentData: any;
  newPageRequest: any;
  getDepartmentId: any;
  getProgramStatus: any;
};

const View = (props: Props) => {
  const commonProps = {
    departId: props.departId,
    apiStatus: props.apiStatus,
    totalpages: props.totalpages,
    handleReset: props.handleReset,
    instituteId: props.instituteId,
    programData: props.programData,
    filterUpdate: props.filterUpdate,
    programStatus: props.programStatus,
    departmentData: props.departmentData,
    newPageRequest: props.newPageRequest,
    getDepartmentId: props.getDepartmentId,
    getProgramStatus: props.getProgramStatus,
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;
