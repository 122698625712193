import React from "react";
import Filter from "../../filter";
import PeersRanking from "../../peersRanking";
import { useTranslation } from "react-i18next";
import InstanceStats from "../../instanceStats";
import TopThreeGraph from "../../topThreeGraph";
import Header from "../../../../../../newHeader";
import Footer from "../../../../../../newFooter";
import HeaderTabs from "../../../../../../headerTabs";
import { Card, Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../../../../../../widgets/pageTitle";
import defaultProfile from "../../../../../../../assets/images/profile.png";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import TimeSpentApiCall from "../../../../graphs/timeSpent/timeSpentApiCall";
import GradeOfCourseApiCall from "../../../../graphs/gradeOfCourse/gradeOfCourseApiCall";
import CompletionOfCourseApiCall from "../../../../graphs/completionOfCourse/completionOfCourseApiCall";
import { isMobile } from "react-device-detect";
import { useSelector } from "react-redux";

type Props = {
  commonProps: {
    programId: any;
    apiStatus: any;
    getProgramId: any;
    peerComparisonData: any;
  };
};

const Browser: React.FC<Props> = ({ commonProps }) => {
  const {t} = useTranslation();
  const userInfo = JSON.parse(localStorage.getItem("userInfo") || "{}");
  const userId = userInfo.userInfo?.uid;

  const currentUser = commonProps.peerComparisonData.find(item => item.userId === userId);
  const filteredData = commonProps.peerComparisonData
    .filter(item => parseFloat(item.completion) > 0 && parseFloat(item.grade) > 0)
    .sort((a, b) => a.rank - b.rank);

  const isVisible = filteredData.length > 2;
  const permissions = useSelector((state: any) => state.userAuthorities.permissions);
  const currentUserRole = useSelector((state: any) => state.globalFilters.currentUserRole);
  return (
    <>
      <Header />
      <HeaderTabs activeTab="performance" />
       <BreadcrumbComponent
          routes={ permissions.menu.dashboard.canView && currentUserRole.shortName !== "admin" ? [
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance (Student)", path: "" },
        ]:
        [
          { name: "Performance", path: "/performance" },
          { name: "Performance (Student)", path: "" },
        ]}
        />
      <Container fluid className="mt-3">
        <PageTitle pageTitle={t('common.performance')} gobacklink="/dashboard" />
      </Container>
      <div className="py-4">
        <Container fluid>
          <InstanceStats />
          <Filter
            programId={commonProps.programId}
            getProgramId={commonProps.getProgramId}
          />
          {isVisible ? (
            <Row>
              <Col lg={6} md={6} sm={12}>
                <Card className="performance-card topThreeCard cardBoxBg">
                  <TopThreeGraph
                    apiStatus={commonProps.apiStatus}
                    peerComparisonData={commonProps.peerComparisonData}
                  />
                </Card>
              </Col>
              <Col lg={6} md={6} sm={12}>
                <Card className="performance-card">
                  <PeersRanking
                    apiStatus={commonProps.apiStatus}
                    peerComparisonData={commonProps.peerComparisonData}
                  />
                </Card>
              </Col>
            </Row>
          ) : (
            currentUser && (
              <div
                className={`${
                  isMobile ? 'd-flex gap-2 align-items-center peer-user mb-2' : 'd-flex gap-3 align-items-center peer-user mb-2'
                } ${currentUser.userId === userId ? 'active' : ''}`}
              >
                {/* <span className="rank">{currentUser.rank}</span> */}
                <img
                  src={currentUser.profilePicUrl || defaultProfile}
                  className="rounded-circle"
                  alt={`${currentUser.firstName} ${currentUser.lastName}`}
                />
                <div>
                  <h6 className="name">{`${currentUser.firstName} ${currentUser.lastName}`}</h6>
                  <ul className={`${isMobile ? 'list-inline m-0 d-flex flex-column' : 'list-inline m-0 d-flex gap-2'}`}>
                    <li className="list-inline-item me-0">
                      <span>{t('performance.completions')}</span>: {currentUser.completion ?? 0}%
                    </li>
                    <li>
                      <span>{t('performance.grade')}</span>: {currentUser.grade ?? 0}%
                    </li>
                    <li>
                      <span>{t('performance.time_spent')}</span>: {currentUser.timespentInString}
                    </li>
                  </ul>
                </div>
                {/* {currentUser.userId === userId && (
                  <span className="badge text-bg-primary ms-auto ranking-user">You</span>
                )} */}
              </div>
            )
          )}
        </Container>
      </div>
      <div className="py-4 performanceBgContainer">
        <Container fluid>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h4>{t('performance.activity_analytics')}</h4>
          </div>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <GradeOfCourseApiCall programId={commonProps.programId} />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <CompletionOfCourseApiCall programId={commonProps.programId} />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <TimeSpentApiCall programId={commonProps.programId} />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </>
  );
};

export default Browser;
