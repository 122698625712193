import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import { Formik, Form } from "formik";
import FieldLabel from "../../../widgets/formInputFields/labels";
import FieldTypeText from "../../../widgets/formInputFields/formTextField";
import FieldErrorMessage from "../../../widgets/formInputFields/errorMessage";
import { postData } from "../../../adapters/coreservices";
import config from "../../../utils/config";
import * as Yup from "yup";
import Errordiv from "../../../widgets/alert/errordiv";
import { useTranslation } from "react-i18next";

const initialValues = {
  newPassword: "",
  confirmPassword: "",
};

const ConfirmUserForm = () => {
  const { t } = useTranslation();
  const location = useLocation().search;
  const [requestToken, setRequestToken] = useState<string | null>(null);
  const [signInLink, setSigninLink] = useState<boolean>(false);
  const redirectUri = config.REDIRECT_URI;
  const oAuthUrl = `${config.OAUTH2_URL}/authorize?response_type=code&client_id=moodle&redirect_uri=${redirectUri}&scope=openid`;
  const [alertStatus, setAlertStatus] = useState(false);
  const [alertMsg, setAlertMsg] = useState({ message: "", alertBoxColor: "" });
  const navigate = useNavigate();

  // Formik Yup validation
  const userFormSchema = Yup.object({
    newPassword: Yup.string()
      .required(t("signup.new_password_required"))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]+$/,
        t("signup.new_password_required")
      )
      .min(8, t("signup.new_password_required")),
    confirmPassword: Yup.string()
      .required(t("signup.confirm_password_required"))
      .test("signup.passwords-match", t("signup.confirm_password_required"), function (value) {
        return value === this.parent.newPassword;
      }),
  });

  useEffect(() => {
    const urlParams = new URLSearchParams(location);
    setRequestToken(urlParams.get("signup.token"));
  }, [location]);

  // handle Form CRUD operations
  const handleFormData = (values: any, { setSubmitting, resetForm }: any) => {
    let endPoint = `/public/confirmUser?token=${requestToken}`;
    postData(endPoint, values, undefined, false)
      .then((res: any) => {
        if (res.status === 200) {
          setAlertStatus(true);
          resetForm();
          setSubmitting(true);
          setAlertMsg({
            message: t("signup.password_set_success"),
            alertBoxColor: "",
          });
          setSigninLink(true);
          setTimeout(() => {
            navigate(oAuthUrl);
          }, 2000);
        } else {
          setAlertStatus(true);
          resetForm();
          setSubmitting(true);
          setAlertMsg({
            message: t("signup.error_processing_request"),
            alertBoxColor: "alert-danger",
          });
        }
      })
      .catch(() => {
        setAlertStatus(true);
        setAlertMsg({
          message: t("signup.error_processing_request"),
          alertBoxColor: "alert-danger",
        });
      });
  };

  return (
    <React.Fragment>
      {alertStatus && (
        <Errordiv
          msg={alertMsg.message}
          cstate
          className="mt-3"
          alertColor={alertMsg.alertBoxColor}
        />
      )}
      {signInLink && (
        <p>
          {t("signup.click_here")}{" "}
          <a href={oAuthUrl} className="ms-1">
            {t("signup.sign_in")}
          </a>
        </p>
      )}
      <Formik
        enableReinitialize={true}
        initialValues={initialValues}
        validationSchema={userFormSchema}
        onSubmit={(values, action) => {
          handleFormData(values, action);
        }}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className="row">
            <div className="col-12 mb-4 text-start">
              <FieldLabel
                htmlfor="newPassword"
                labelText={t("signup.create_password")}
                required="required"
                className="form-label"
              />
              <FieldTypeText
                type="password"
                name="newPassword"
                placeholder={t("signup.new_password_required")}
              />
              <FieldErrorMessage
                errors={errors.newPassword}
                touched={touched.newPassword}
                msgText={t("signup.new_password_required")}
              />
            </div>
            <div className="col-12 mb-4 text-start">
              <FieldLabel
                htmlfor="confirmPassword"
                labelText={t("signup.confirm_password")}
                required="required"
                className="form-label"
              />
              <FieldTypeText
                type="password"
                name="confirmPassword"
                placeholder={t("signup.confirm_password")}
              />
              <FieldErrorMessage
                errors={errors.confirmPassword}
                touched={touched.confirmPassword}
                msgText={t("signup.confirm_password_required")}
              />
            </div>
            <div className="col-12 mb-4 d-grid">
              <Button
                type="submit"
                variant="primary"
                disabled={signInLink && isSubmitting}
              >
                {t("signup.submit")}
              </Button>{" "}
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
};

export default ConfirmUserForm;
