import View from "./view";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { getData } from "../../../../adapters/microservices";

const CoursePerformanceDetails = () => {
  const { programid, prgname } = useParams();

  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: 30,
  });

  // get current user Role wise id
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  // Get current institute id
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const [apiStatus, setApiStatus] = useState("");
  const [courseData, setCourseData] = useState<any[]>([]);
  const [courseDataLength, setCourseDataLength] = useState([]);

  // get view-details Program API call === >>>
  useEffect(() => {
    if (programid) {
      setApiStatus("started");
      getData(
        `/performance/${currentInstitute}/${currentUserRole.id}/${programid}/course-analytics`,
        filterUpdate
      )
        .then((res: any) => {
          if (res.data !== "" && res.status === 200) {
            const proData = res.data.items.flatMap(
              (sem: any) => sem.courseAnalyticsDtos
            );
            setCourseDataLength(proData);
            setCourseData(res.data.items);
          }
          setApiStatus("finished");
        })
        .catch((err: any) => {
          console.log(err);
          setApiStatus("finished");
        });
    }
  }, [programid]);

  return (
    <React.Fragment>
      <View
        prgname={prgname}
        courseData={courseData}
        apiStatus={apiStatus}
        courseDataLength={courseDataLength}
      />
    </React.Fragment>
  );
};

export default CoursePerformanceDetails;
