import React from "react";
import Mobile from "./mobile";
import Browser from "./browser";
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  apiStatus: any;
  programId: any;
  courseData: any;
  getProgramId: any;
  programStatus: any;
  getProgramStatus: any;
  courseDataLength: any;
};

const View = (props: Props) => {
  const commonProps = {
    programId: props.programId,
    apiStatus: props.apiStatus,
    courseData: props.courseData,
    getProgramId: props.getProgramId,
    programStatus: props.programStatus,
    getProgramStatus: props.getProgramStatus,
    courseDataLength: props.courseDataLength,
  };

  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  );
};

export default View;
