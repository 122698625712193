import "../../style.scss";
import "../../mobileStyle.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CourseCompletion from "./courseCompletion";
import { customPagination } from "../../pagination";
import PerformanceCard from "../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const CompletionOfCourseApiCall = ({ programId }: any) => {
  const {t} = useTranslation();
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const [filterUpdate, setFilterUpdate] = useState({
    pageNumber: 0,
    pageSize: customPagination.PERPAGE,
  });

  const [completionCourseData, setCompletionCourseData] = useState({
    items: [],
    pages: { totalElements: 0, totalPages: 0 },
  });

  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${currentUserRole.id}/${programId}/course-average-completion`,
    {
      ...filterUpdate,
      ...(programId && { programId }),
    }
  );

  // Trigger the refetch when dependencies change
  useEffect(() => {
    if (currentUserRole.id && programId) {
      refetch();
    }
  }, [currentUserRole.id, programId, filterUpdate]);

  useEffect(() => {
    if (apiData) {
      setCompletionCourseData({
        items: apiData.items || [],
        pages: apiData.pages || { totalElements: 0, totalPages: 0 },
      });
    }
  }, [apiData]);

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate((prevFilterUpdate) => ({
      ...prevFilterUpdate,
      pageNumber: pageRequest,
    }));
  };

  const handlerIncrement = () => {
    if (filterUpdate.pageNumber < completionCourseData.pages.totalPages - 1) {
      newPageRequest(filterUpdate.pageNumber + 1);
    }
  };

  const handlerDecrement = () => {
    if (filterUpdate.pageNumber > 0) {
      newPageRequest(filterUpdate.pageNumber - 1);
    }
  };

  return (
    <PerformanceCard
      title={t('performance.average')} 
      subtitle={t('performance.completion_course')} 
      handlerDecrement={handlerDecrement}
      handlerIncrement={handlerIncrement}
      currentPage={filterUpdate.pageNumber}
      totalPages={completionCourseData.pages.totalPages}
    >
      <CourseCompletion
        error={error}
        loading={loading}
        completionCourseData={completionCourseData.items}
      />
    </PerformanceCard>
  );
};

export default CompletionOfCourseApiCall;
