
import {
  Bar,
  XAxis,
  YAxis,
  Legend,
  Tooltip,
  BarChart,
  Rectangle,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import React from "react";
import { useTranslation } from "react-i18next";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";

// Custom shape for bars
const CustomBarShape = (props: any) => {
  const { fill, x, y, width, height } = props;
  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      radius={[6, 6, 0, 0]}
    />
  );
};

// Custom tooltip for the bar chart
const CustomTooltip = ({ active, payload }: any) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    return (
      <div className="chart-tooltip">
        <div className="intro" style={{ color: "#0A68C5" }}>
          {`${t('performance.active')}: ${payload[0].value}`}
        </div>
        <div className="desc" style={{ color: "gray" }}>
          {`${t('performance.inactive')}: ${payload[1].value}`}
        </div>
      </div>
    );
  }
  return null;
};

// Format date for the x-axis labels
const formatDate = (dateString: string) => {
  const options: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "short",
  };
  return new Date(dateString).toLocaleDateString(undefined, options);
};

// Main component
const ActiveInactiveUser = ({ activeInactiveUser = [], loading, error }: any) => {
  const { t } = useTranslation();

  const data = activeInactiveUser.map((userData: any) => ({
    name: formatDate(userData.visitDate),
    Active: userData.activeUsers,
    Inactive: userData.inactiveUsers,
  }));

  // Render loading, error, or chart based on state
  return (
    <div className="chart-container">
      {loading && activeInactiveUser.length === 0 ? (
        <div className="center-loader">
          <RouterLadyLoader status={true} />
        </div>
      ) : error || activeInactiveUser.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            data={data}
            margin={{ top: 0, right: 10, left: 10, bottom: 24 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="name"
              label={{
                value: t('common.date'),
                position: "insideBottom",
                offset: -12,
                dy: 10,
              }}
            />
            <YAxis
              label={{
                value: t('performance.number_of_users'),
                angle: -90,
                position: "insideLeft",
                offset: 0,
                dy: 60,
                dx: -2,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Legend verticalAlign="top" align="right" height={36} />
            <Bar
              dataKey="Active"
              shape={<CustomBarShape />}
              fill="#0A68C5"
              barSize={20}
            />
            <Bar
              dataKey="Inactive"
              shape={<CustomBarShape />}
              fill="#E4E4E4"
              barSize={20}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ActiveInactiveUser;

