import View from "./view";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { pagination } from "../../../../utils/pagination";
import { getData } from "../../../../adapters/microservices";

const ProgramPerformanceDetails = () => {
  const dummyData: any = {
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  };

  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
  });

  // get current user Role wise id
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  // Get current institute id
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const [departId, setDepartId] = useState(0);
  const [apiStatus, setApiStatus] = useState("");
  const [programStatus, setProgramStatus] = useState("");
  const [departmentData, setDepartmentData] = useState(dummyData);
  const [viewProgramDetails, setViewProgramDetails] = useState(dummyData);

  // get Department API call === >>>
  useEffect(() => {
    if (currentUserRole.shortName !== "editingteacher") {
      if (currentInstitute > 0) {
        getData(`/${currentInstitute}/departments`, filterUpdate)
          .then((result: any) => {
            if (result.data !== "" && result.status === 200) {
              setDepartmentData(result.data);
            }
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    } else {
      if (currentUserRole.id > 0) {
        let packet: any[] = [];
        getData(`/${currentUserRole.id}/dashboard`, {})
          .then((result: any) => {
            if (result.data !== "" && result.status === 200) {
              Object.entries(result.data.departments).map(
                ([key, value]: any) => {
                  packet.push({ id: +key, name: value });
                }
              );
            }
            setDepartmentData((preValue: any) => ({
              ...preValue,
              items: packet,
            }));
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    }
  }, [filterUpdate, currentInstitute, currentUserRole.id]);

  // get view-details Program API call === >>>
  useEffect(() => {
    if (currentInstitute > 0 && currentUserRole.id) {
      setApiStatus("started");
      getData(
        `/performance/${currentUserRole.id}/${currentInstitute}/view-details`,
        {
          ...filterUpdate,
          ...(departId && { departmentId: departId }),
          ...(programStatus && { status: programStatus }),
        }
      )
        .then((res: any) => {
          if (res.data !== "" && res.status === 200) {
            setViewProgramDetails(res.data);
          }
          setApiStatus("finished");
        })
        .catch((err: any) => {
          console.log(err);
          setApiStatus("finished");
        });
    }
  }, [
    filterUpdate,
    currentInstitute,
    departId,
    programStatus,
    currentUserRole,
  ]);

  // get department id Handler
  const departmentHandler = (id: number) => {
    setDepartId(id);
  };

  // get program status Handler
  const programStatusHandler = (status: string) => {
    setProgramStatus(status);
  };

  // reset handler
  const handleReset = () => {
    setProgramStatus("");
    setDepartId(0);
    setDepartmentData(dummyData);
    setFilterUpdate({
      pageNumber: 0,
      pageSize: 30,
    });
  };

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };

  return (
    <React.Fragment>
      <View
        departId={departId}
        apiStatus={apiStatus}
        handleReset={handleReset}
        filterUpdate={filterUpdate}
        programStatus={programStatus}
        instituteId={currentInstitute}
        newPageRequest={newPageRequest}
        getDepartmentId={departmentHandler}
        departmentData={departmentData.items}
        programData={viewProgramDetails.items}
        getProgramStatus={programStatusHandler}
        totalpages={viewProgramDetails.pager.totalPages}
      />
    </React.Fragment>
  );
};
export default ProgramPerformanceDetails;
