import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useCallback, useEffect, useState } from "react";
import PerformanceCard from "../../cardComponent/performanceCard";
// import ActiveInactiveUser from "../../activityAnalytics/activeInactiveUser";
import ActiveInactiveUser from "./activeInactiveUser";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const ActiveInactiveUserApiCall = ({ programId,departmentId, instituteId }: any) => {
  const {t} = useTranslation();
  const [startEndDate, setStartEndDate] = useState<string>("");
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  // Using the custom hook to fetch data
  const { apiData = [], loading, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${instituteId}/${currentUserRole.id}/program-wise-active-users`,
    { ...(programId && { programId: programId }),
    ...(departmentId && { departmentId: departmentId })
   }
  );

  useEffect(() => {
    if(currentUserRole.shortName === "editingteacher"){
      if (instituteId > 0 && currentUserRole.id && programId > 0) {
        refetch();
      }
    }else{
      if (instituteId > 0 && currentUserRole.id) {
        refetch();
      }
    }
  }, [programId,departmentId, currentUserRole.id, instituteId]);

  const formatDate = useCallback((dateStr: string) => {
    const dateObject = new Date(dateStr);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(dateObject);
  }, []);

  useEffect(() => {
    if (apiData.length > 0) {
      const startDate = formatDate(apiData[0].visitDate);
      const endDate = formatDate(apiData[apiData.length - 1].visitDate);
      const dateRange = `${startDate} to ${endDate}`;
      setStartEndDate(dateRange);
    }else {
      setStartEndDate(""); // Clear date range if no data
    }
  }, [apiData, formatDate]);

  return (
      <PerformanceCard
        title={t('performance.number_of')}
        subtitle={t('performance.activeInactiveUser')}
        newTitle={startEndDate}
      >
        <ActiveInactiveUser
          error={error}
          loading={loading}
          activeInactiveUser={apiData}
        />
      </PerformanceCard>
  );
};

export default ActiveInactiveUserApiCall;
