import "./style.scss";
import "./mobileStyle.scss";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { pagination } from "../../../utils/pagination";
import { getData as departData } from "../../../adapters/microservices";

const ProgramAnalyticsFilter = ({
  getDepartmentId,
  departmentId,
  instituteId,
  programStatus,
  getProgramStatus,
}: any) => {
  const {t} = useTranslation();
  const dummyData: any = {
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  };

  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
  });

  const [departmentData, setDepartmentData] = useState(dummyData);

  // get Department API call === >>>
  useEffect(() => {
    if (instituteId > 0) {
      departData(`/${instituteId}/departments`, filterUpdate)
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setDepartmentData(result.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [filterUpdate, instituteId]);

  //  Get current Department id
  const getCurrentDepartment = (e: any) => {
    const departId = parseInt(e.target.value);
    getDepartmentId(departId);
  };

  const statusHandlerChange = (e: any) => {
    getProgramStatus(e.target.value.toLowerCase());
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="filter-wrapper mt-2 bg-transparent justify-content-center ps-0 pe-0">
          <Row className="g-2">
            <Col className="col-auto">
              <label>{t('performance.department')}</label>
              <select
                className="form-select"
                name="department"
                onChange={getCurrentDepartment}
                value={departmentId}
                // disabled={instituteId == 0}
              >
                <option value={0}>{t('performance.all_department')}</option>
                {departmentData.items.map((option: any) => 
                 option.published && (
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
              </select>
            </Col>
            <Col className="col-auto">
              <label>{t('performance.program-status')}</label>
              <select
                className="form-select"
                name="status"
                onChange={statusHandlerChange}
                value={programStatus}
              >
                <option value="">{t('common.all')}</option>
                <option value="active">{t('performance.active')}</option>
                <option value="completed">{t('performance.completed')}</option>
              </select>
            </Col>
          </Row>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ProgramAnalyticsFilter;
