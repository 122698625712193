
import {
  BarChart,
  Bar,
  Rectangle,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";
import NoDataFoundImg from "../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../globals/globalLazyLoader/routerLadyLoader";

const CustomBarShape = (props: any) => {

  const { fill, x, y, width, height } = props;
  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      radius={[6, 6, 0, 0]}
    />
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  const { t } = useTranslation();
  if (active && payload && payload.length) {
    const { instituteName } = payload[0].payload;
    const numberOfUsers = payload[0].payload['Number of users'];

    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px', border: '1px solid #cccccc' }}>
        <p className="label" style={{ color: "#337ab7" }}>{`${instituteName}`}</p>
        <p className="intro">{`${t('performance.number_of_users')}: ${numberOfUsers}`}</p>
      </div>
    );
  }
  return null;
};

export const CustomXAxisTick = ({ x, y, payload, instituteNames }: any) => {
  const instituteName = instituteNames[payload.value];
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <BSTooltip id={`tooltip-${payload.value}`}>{instituteName}</BSTooltip>
      }
    >
      <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </OverlayTrigger>
  );
};

const NumberOfStudent = ({ data, loading, error }: any) => {
  const { t } = useTranslation();
  // Create a mapping of shortCode to instituteName
  const instituteNames = data.reduce(
    (
      acc: { [x: string]: any },
      item: { shortCode: string | number; instituteName: any }
    ) => {
      acc[item.shortCode] = item.instituteName;
      return acc;
    },
    {}
  );

  const instituteData = data.map((userData: any) => {
    return {
      instituteName: userData.instituteName,
      shortCode: userData.shortCode,
      "Number of users": userData.userCount,
    };
  });

  return (
    <div className="chart-container">
      <ResponsiveContainer width="100%" height={300}>
        {loading ? (
          <div className="center-loader">
            <RouterLadyLoader status={true} />
          </div>
        ) : error || instituteData.length === 0 ? (
          <div className="d-flex justify-content-center align-center">
            <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
          </div>
        ) : (
          <BarChart
            data={instituteData}
            margin={{ top: 5, right: 10, left: 0, bottom: 20 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="shortCode"
              tick={(props) => (
                <CustomXAxisTick {...props} instituteNames={instituteNames} />
              )}
              label={{
                value: t('performance.institutes'),
                position: "insideBottom",
                offset: -10,
                dy: 10,
              }}
            />
            <YAxis
              label={{
                value: t('performance.number_of_users'),
                angle: -90,
                position: "insideLeft",
                dy: 50,
                offset: -6,
                dx: 18,
              }}
            />
            <Tooltip content={<CustomTooltip />} />
            <Bar
              dataKey="Number of users"
              fill="#0A68C5"
              shape={<CustomBarShape />}
              barSize={30}
            />
          </BarChart>
        )}
      </ResponsiveContainer>
    </div>
  );
};

export default NumberOfStudent;


