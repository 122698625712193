import React from "react";
import { useSelector } from "react-redux";
import StudentPerformance from "./roles/student";
import TeacherPerformance from "./roles/teacher";
import SuperAdminPerformance from "./roles/superAdmin";
// import InstituteAdminPerformance from "./instituteAdmin";
import InstituteAdminPerformance from "./roles/instituteAdmin";
import DepartmentHeadPerformance from "./roles/departmentHead";
import ProgramCoordinatorPerformance from "./roles/programCoordinator";

type Props = {};

const Performance = (props: Props) => {
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );
  
  return (
    <>
      {currentUserRole !== undefined &&
      currentUserRole.shortName === "student" ? 
        <StudentPerformance />
       : currentUserRole.shortName === "editingteacher" || currentUserRole.shortName === "teacher" ? 
        <TeacherPerformance />
       : currentUserRole.shortName === "instituteadmin" ? 
        <InstituteAdminPerformance />
       : currentUserRole.shortName === "programhead" ? <ProgramCoordinatorPerformance /> 
       : currentUserRole.shortName === "departmenthead" ? <DepartmentHeadPerformance /> 
       :
        <SuperAdminPerformance />
      }
    </>
  );
};

export default Performance;
