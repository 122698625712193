import { useState, useEffect, useCallback } from "react";
import { getData } from "../../adapters/microservices";

interface FetchState<T> {
  apiData: T | null;
  loading: boolean;
  error: string | null;
  apiStatus: string; 
}

const useApiGetMethodHook = <T,>(
  URL: string,
  options?: RequestInit
): FetchState<T> & { refetch: () => void } => {
  const [apiData, setApiData] = useState<any>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [error, setError] = useState<string | null>(null);
  const [apiStatus, setApiStatus] = useState<string>("");

  const fetchData = useCallback(async () => {
    setLoading(true);
    setError(null);
    setApiStatus("started");
    try {
      const res = await getData(URL, options);
      if (res.data !== "" && res.status === 200) {
        setApiData(res.data);
        setApiStatus("finished");
      } else {
        setError("Error: Data not received or status not 200");
      }
    } catch (error: any) {
      setError(error);
      setApiStatus("finished");
    } finally {
      setLoading(false);
      setApiStatus("finished");
    }
  }, [URL, options]);

  // useEffect(() => {
  //   fetchData();
  // }, []);

  return { apiData, loading, error, apiStatus, refetch: fetchData };
};

export default useApiGetMethodHook;
