import { IoBook } from "react-icons/io5";
import { useSelector } from "react-redux";
import InstanceModal from "./instanceModal";
import { useTranslation } from "react-i18next";
import { Card, Row, Col, Modal } from "react-bootstrap";
import { FaUsers, FaGraduationCap } from "react-icons/fa";
import { getData } from "../../../../../adapters/microservices";
import React, { useCallback, useEffect, useState } from "react";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";

interface PerformanceOverview {
  completion?: number;
  grades?: number;
  badges?: number;
  totalBadges?: number;
  certificates?: number;
  totalCertificates?: number;
  credits?: number;
  totalCredits?: number;
  instituteAndusersCount?: {
    totalUserCount: number;
    activeUserCount: number;
  };
  programCount?: {
    totalProgramCount: number;
    publishedProgramCount: number;
  };
  courseCount?: {
    totalCourseCount: number;
    publishedCourseCount: number;
  };
}
const InstanceStats = () => {
  const {t} = useTranslation();
  const [peformanceOverview, setPeformanceOverview] =
    useState<PerformanceOverview>({});
  const [allBadges, setAllBadges] = useState<any[]>([]);
  const [allCertificates, setAllCertificates] = useState<any[]>([]);
  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: 30,
  });

  const [apiStatus, setApiStatus] = useState<string>("");
  // const [peformanceOverview, setPerformanceDetails] = useState<peformanceOverview>({});
  const [showModal, setShowModal] = useState<any>({status: false, statusLabel: ""});
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  useEffect(() => {
    if (currentInstitute > 0) {
      setApiStatus("started");
      getData(
        `/performance/student/${currentInstitute}/get-peformance-overview`,
        filterUpdate
      )
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setPeformanceOverview(result.data);
          }
          setApiStatus("finished");
        })
        .catch((err: any) => {
          console.log(err);
          setApiStatus("finished");
        });
    }
  }, [currentInstitute]);

  // get all badges
  useEffect(() => {
    if (currentInstitute > 0) {
      getData(`/performance/${currentInstitute}/badges`, {})
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setAllBadges(result.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [currentInstitute]);

  // get all certificates
  useEffect(() => {
    if (currentInstitute > 0) {
      getData(`/performance/${currentInstitute}/certificate-details`, {})
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setAllCertificates(result.data);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [currentInstitute]);

  const stats = [
    {
      icon: <FaUsers />,
      value: peformanceOverview?.completion != undefined ? peformanceOverview?.completion + "%" || 0 : 0,
      // total: peformanceOverview?.completion || 0,
      label: t('performance.average_completion'),
    },
    {
      icon: <FaGraduationCap />,
      value: peformanceOverview?.grades != undefined ? peformanceOverview?.grades + "%" || 0 : 0,
      // total: peformanceOverview?.grades || 0,
      label: t('performance.average_grade'),
    },
    {
      icon: <IoBook />,
      value: peformanceOverview?.badges || 0,
      total: peformanceOverview?.totalBadges || 0,
      label: t('performance.badges'),
    },
    {
      icon: <IoBook />,
      value: peformanceOverview?.certificates || 0,
      total: peformanceOverview?.totalCertificates || 0,
      label: t('performance.certificates'),
    },
    {
      icon: <IoBook />,
      value: peformanceOverview?.credits || 0,
      total: peformanceOverview?.totalCredits || 0,
      label: t('performance.credits'),
    },
  ];

  const handleClick = useCallback(
    (label: string) => {
      if (label !== "Badges" && label !== "Certificates") return;
      if (label === "Badges" && allBadges.length !== 0){
        setShowModal({status: true, statusLabel: label});
      }else if(label === "Certificates" && allCertificates.length !== 0){
        setShowModal({status: true, statusLabel: label});
      }else{
        return
      }
    },
    [setShowModal, allBadges, allCertificates]
  );

  return (
    <>
      <Row className="justify-content-center">
        {stats.map((stat, index) => (
          <Col key={index} lg={3} md={4} sm={6}>
            <div
              className="instancestat-wrapper mb-3"
              style={{
                cursor:
                  stat.label === "Badges" && allBadges.length > 0 || stat.label === "Certificates" && allCertificates.length > 0
                    ? "pointer"
                    : "default",
              }}
              onClick={() => handleClick(stat.label)}
            >
              <Card>
                <Card.Body className="d-flex align-items-center gap-3">
                  {apiStatus === "started" ? (
                    <div className="center-loader">
                      <RouterLadyLoader status={true} />
                    </div>
                  ) : (
                    <>
                      <div className="stat-icon rounded-circle">
                        {stat.icon}
                      </div>
                      <div className="d-flex flex-column stat-values">
                        <div>
                          <span>{stat.value}</span>
                          {stat.label !== "Average Completion" && stat.label !== "Average Grade" &&(
                            <span className="total"> / {stat.total}</span>
                           )}
                        </div>
                        <label>{stat.label}</label>
                      </div>
                      {stat.label === "Badges" && allBadges.length !== 0 && (
                        <button className="btn btn-sm btn-primary ms-auto">
                          {t('performance.view')}
                        </button>
                      )}
                      {stat.label === "Certificates" && allCertificates.length !== 0 && (
                        <button className="btn btn-sm btn-primary ms-auto">
                          {t('performance.view')}
                        </button>
                      )}
                    </>
                  )}
                </Card.Body>
              </Card>
            </div>
          </Col>
        ))}
      </Row>
      <InstanceModal
        show={showModal.status}
        onHide={() => setShowModal({status: false, statusLabel: showModal.statusLabel})}
        allBadges={allBadges}
        allCertificates={allCertificates}
        label={showModal.statusLabel}
      />
    </>
  );
};

export default InstanceStats;
