import { IoBook } from "react-icons/io5";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Card, Row, Col } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import { FaUsers, FaGraduationCap } from "react-icons/fa";
import { getData } from "../../../adapters/microservices";
import RouterLadyLoader from "../../../globals/globalLazyLoader/routerLadyLoader";

interface PerformanceDetails {
  instituteAndusersCount?: {
    totalUserCount: number;
    activeUserCount: number;
  };
  programCount?: {
    totalProgramCount: number;
    publishedProgramCount: number;
  };
  courseCount?: {
    totalCourseCount: number;
    publishedCourseCount: number;
  };
}

const InstanceStats = () => {
  const {t} = useTranslation();

  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: 30,
  });

  const [apiStatus, setApiStatus] = useState<string>("");
  const [performanceDetails, setPerformanceDetails] = useState<PerformanceDetails>({});

  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  useEffect(() => {
    if (currentInstitute > 0) {
      setApiStatus("started");
      getData(`/performance/${currentUserRole.id}/${currentInstitute}/performance-statics`, filterUpdate)
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setPerformanceDetails(result.data);
          }
          setApiStatus("finished");
        })
        .catch((err: any) => {
          console.log(err);
          setApiStatus("finished");
        });
    }
  }, [currentInstitute, currentUserRole.id]);

  const stats = [
    {
      icon: <FaUsers />,
      total: performanceDetails?.instituteAndusersCount?.totalUserCount || 0,
      value: performanceDetails?.instituteAndusersCount?.activeUserCount || 0,
      label: t('performance.active_users'),
    },
    {
      icon: <FaGraduationCap />,
      value: performanceDetails?.programCount?.publishedProgramCount || 0,
      total: performanceDetails?.programCount?.totalProgramCount || 0,
      label: t('performance.programs_published')
    },
    {
      icon: <IoBook />,
      value: performanceDetails?.courseCount?.publishedCourseCount || 0,
      total: performanceDetails?.courseCount?.totalCourseCount || 0,
      label: t('performance.courses_published'),
    },
  ];

  return (
    <Row className="justify-content-center">
      {stats.map((stat, index) => (
        <Col key={index} md={3}>
          <div className="instancestat-wrapper mb-3">
            <Card className="m-1">
              <Card.Body className="d-flex align-items-center gap-3">
                {apiStatus === "started" ? (
                  <div className="center-loader">
                    <RouterLadyLoader status={true} />
                  </div>
                ) : (
                  <>
                    <div className="stat-icon rounded-circle">
                      {stat.icon}
                    </div>
                    <div className="d-flex flex-column stat-values">
                      <div>
                        <span>{stat.value}</span>
                        <span className="total"> / {stat.total}</span>
                      </div>
                      <label>{stat.label}</label>
                    </div>
                  </>
                )}
              </Card.Body>
            </Card>
          </div>
        </Col>
      ))}
    </Row>
  );
};

export default InstanceStats;
