import React from "react";
import { useTranslation } from "react-i18next";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer } from "recharts";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";

const COLORS = ["#7DAFE0", "#0A68C5", "#c2ddf7", "#b3cfea", "#e1eefb"];

const MostVisitedActivity = ({
  mostVisitData,
  apiStatus,
  loading,
  error,
}: any) => {
  const {t} = useTranslation();

  const filterVisitsCount = mostVisitData.some(
    (elem: { visits: number }) => elem.visits > 0
  );
  const data = filterVisitsCount
    ? mostVisitData.map((program: any) => {
        return {
          name: program.name.charAt(0).toUpperCase() + program.name.slice(1),
          value: program.visits,
        };
      })
    : [];

  return (
    <div className="chart-container">
      {apiStatus === "started" && data.length === 0 ? (
        <div
          className="center-loader"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <RouterLadyLoader status={true} />
        </div>
      ) : apiStatus === "finished" && data.length === 0 ? (
        <div className="d-flex justify-content-center align-center">
          <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
        </div>
      ) : (
        <>
          <div className="mt-2 d-flex justify-content-center gap-3 flex-wrap">
            {data.map((entry: any, index: number) => (
              <div key={`item-${index}`}>
                <span
                  style={
                    entry.value !== 0 ? { backgroundColor: COLORS[index] } : {}
                  }
                  className="d-inline-block rounded-circle chart-legend"
                />
                {entry.value !== 0 && (entry.name.length > 17 ? entry.name.slice(0, 17) + "..." : entry.name)}
              </div>
            ))}
          </div>
          <ResponsiveContainer width="100%" height={400}>
            {apiStatus === "finished" && data.length === 0 ? (
              <div className="d-flex justify-content-center align-center">
                <img
                  className="nodata-img"
                  alt="No Data"
                  src={NoDataFoundImg}
                />
              </div>
            ) : (
              <PieChart>
                <Pie
                  data={data}
                  cx="50%"
                  cy="58%"
                  innerRadius="60%"
                  outerRadius="100%"
                  startAngle={180}
                  endAngle={0}
                  dataKey="value"
                  labelLine={false}
                >
                  {data.map((entry: any, index: number) => (
                    <Cell key={`cell-${index}`} fill={COLORS[index]} />
                  ))}
                </Pie>
                <Tooltip
                  content={<CustomTooltip />}
                  cursor={{ fill: "transparent" }}
                />
              </PieChart>
            )}
          </ResponsiveContainer>
        </>
      )}
    </div>
  );
};

const CustomTooltip = ({ active, payload }: any) => {
  const {t} = useTranslation();
  if (active && payload && payload.length) {
    const formattedVisits = payload[0].value.toLocaleString();
    return (
      <div className="chart-tooltip">
        <h6>{`${payload[0].name}`}</h6>
        <div>{`${t('performance.visits')}: ${formattedVisits}`}</div>
      </div>
    );
  }
  return null;
};

export default MostVisitedActivity;
