import React from "react";
import Filter from "../../filter";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import CoursePerformanceTable from "../../table";
import Header from "../../../../../../../newHeader";
import Footer from "../../../../../../../newFooter";
import HeaderTabs from "../../../../../../../headerTabs";
import PageTitle from "../../../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../../../widgets/breadcrumb";
import BottomWave from "../../../../../../../../assets/images/background/bg-bottom.svg";
import bgLeft from "../../../../.././../../../assets/images/background/bg-admin-left.svg";

type Props = {
  commonProps: {
    apiStatus: any;
    programId: any;
    courseData: any;
    getProgramId: any;
    programStatus: any;
    getProgramStatus: any;
    courseDataLength: any;
  };
};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Header />
      <HeaderTabs activeTab="performance" />
      <BreadcrumbComponent
        routes={[
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance", path: "/performance" },
          { name: "Course Performance Analytics", path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
          <Container fluid>
            <PageTitle
              pageTitle={t(`performance.course_per_analytics`)}
              gobacklink="/performance"
            />
            <Filter
              programId={props.commonProps.programId}
              apiStatus={props.commonProps.apiStatus}
              getProgramId={props.commonProps.getProgramId}
              programStatus={props.commonProps.programStatus}
              getProgramStatus={props.commonProps.getProgramStatus}
            />

            <CoursePerformanceTable
              apiStatus={props.commonProps.apiStatus}
              courseData={props.commonProps.courseData}
              courseDataLength={props.commonProps.courseDataLength}
            />
          </Container>
        </div>
      </div>
      <Footer />
      <div className="position-relative">
        <img src={bgLeft} className="left-cicle" alt="left-cicle" />
      </div>
      <div className="bottom-bg">
        <img src={BottomWave} alt="bottom wave" />
      </div>
    </React.Fragment>
  );
};

export default Browser;
