import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import rankingchart from "../../../../../../src/assets/images/ranking-chart.svg";
import defaultProfile from "../../../../../assets/images/profile.png";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from '../../../../../globals/globalLazyLoader/routerLadyLoader';
interface PeerComparisonData {
  firstName: string;
  lastName: string;
  completion: number;
  grade: number;
  timespentInString: string;
  rank: number;
  userId: number;
  profilePicUrl?: string; // Assuming this is part of the data
}

interface PeersRankingProps {
  peerComparisonData: PeerComparisonData[];
  apiStatus: 'started' | 'completed' | 'failed';
}

const TopThreeGraph: FC<PeersRankingProps> = ({ peerComparisonData, apiStatus }) => {
  const {t} = useTranslation();

  const filteredData = peerComparisonData.filter(item =>
    (item.rank < 4 && parseFloat(item.completion) > 0 && parseFloat(item.grade) > 0)
).sort((a, b) => a.rank - b.rank);

  return (
    <React.Fragment>
      <div className={"peer-ranking-bg"}>
      {apiStatus === "started" && peerComparisonData.length === 0 ? (
        <div className="center-loader">
        <RouterLadyLoader status={true} />  
      </div>
      ) : ( filteredData.length > 0 ?
        <>
      {filteredData.map((data) => {
        let className;
        if (data.rank == 1) {
          className = 'peer-ranking one';
        } else if (data.rank == 2) {
          className = 'peer-ranking two';
        } else if (data.rank == 3) {
          className = 'peer-ranking three';
        } else {
          return null; // Skip rendering for ranks other than 1, 2, or 3
        }

        return (
            <div className='peer-ranking-info'>
              <div className={className}>
                <img
                  src={data.profilePicUrl || defaultProfile}
                  alt="student image"
                  className='rounded-circle'
                />
              {/* )} */}
                <h6 className='name'>{`${data.firstName} ${data.lastName}`}</h6>
                <div className='pr-info'>
                  <div><b>{t('performance.completions')} :</b> {data.completion || 0}%</div>
                  <div><b>{t('performance.grade')} :</b> {data.grade || 0}%</div>
                  <div><b>{t('performance.time_spent')} :</b> {data.timespentInString || 0}</div>
                </div>
              </div>
            </div>
        );
      })}
      </>
       :
      //  <img src={NoDataFoundImg} alt="student image" />
      ""
      ) }
      </div>
    </React.Fragment>
  );
};

export default TopThreeGraph;






// import React, { FC } from 'react';
// import rankingchart from "../../../../../../src/assets/images/ranking-chart.svg";
// import defaultProfile from "../../../../../assets/images/profile.png";
// import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
// import RouterLadyLoader from '../../../../../globals/globalLazyLoader/routerLadyLoader';

// interface PeerComparisonData {
//   firstName: string;
//   lastName: string;
//   completion: number;
//   grade: number;
//   timespentInString: string;
//   rank: number;
//   userId: number;
//   profilePicUrl?: string; // Assuming this is part of the data
// }

// interface PeersRankingProps {
//   peerComparisonData: PeerComparisonData[];
//   apiStatus: 'started' | 'completed' | 'failed';
// }

// const TopThreeGraph: FC<PeersRankingProps> = ({ peerComparisonData, apiStatus }) => {
//   // Ensure at least 3 items
//   const minItems = 3;
//   const dataToShow = [...peerComparisonData];

//   // Add placeholders if there are fewer than 3 items
//   if (dataToShow.length < minItems) {
//     for (let i = dataToShow.length; i < minItems; i++) {
//       dataToShow.push({
//         firstName: `Placeholder ${i + 1}`,
//         lastName: '',
//         completion: 0,
//         grade: 0,
//         timespentInString: '',
//         rank: i + 1,
//         userId: -1, // Placeholder ID
//         profilePicUrl: defaultProfile,
//       });
//     }
//   }

//   // Filter and sort peer comparison data
//   const filteredData = dataToShow
//     .filter(item => item.rank < 4)
//     .sort((a, b) => a.rank - b.rank);

//   // Conditional rendering
//   if (apiStatus === "started" && peerComparisonData.length === 0) {
//     return (
//       <div className="center-loader">
//         <RouterLadyLoader status={true} />
//       </div>
//     );
//   }

//   if (filteredData.length === 0) {
//     return <img src={NoDataFoundImg} alt="No data found" />;
//   }

//   return (
//     <div className={`${filteredData.length > 0 ? 'peer-ranking-bg' : ''}`}>
//       {filteredData.map((data) => {
//         let className = '';
//         switch (data.rank) {
//           case 1:
//             className = 'peer-ranking one';
//             break;
//           case 2:
//             className = 'peer-ranking two';
//             break;
//           case 3:
//             className = 'peer-ranking three';
//             break;
//           default:
//             return null; // Skip rendering for ranks other than 1, 2, or 3
//         }

//         return parseFloat(data.completion) > 0 && parseFloat(data.grade) > 0 ? (
//           <div className='peer-ranking-info' key={data.userId}>
//             <div className={className}>
//               <img
//                 src={data.profilePicUrl || defaultProfile}
//                 alt="student image"
//                 className='rounded-circle'
//               />
//               <h6 className='name'>{`${data.firstName} ${data.lastName}`}</h6>
//               <div className='pr-info'>
//                 <div><b>Completion :</b> {data.completion || 0}%</div>
//                 <div><b>Grade :</b> {data.grade || 0}%</div>
//                 <div><b>Time Spent :</b> {data.timespentInString || 0}</div>
//               </div>
//             </div>
//           </div>
//         ) : 
//         <div className='peer-ranking-info' key={data.userId}>
//          <div className={className}>
//            <img
//             src={data.profilePicUrl || defaultProfile}
//             alt="student image"
//             className='rounded-circle'
//           />
//           <h6 className='noData mt-3'>No Data</h6>
//           {/* <div className='pr-info'>
//             <div><b>Completion :</b> {data.completion || 0}%</div>
//             <div><b>Grade :</b> {data.grade || 0}%</div>
//             <div><b>Time Spent :</b> {data.timespentInString || 0}</div>
//           </div> */}
//         </div>
//       </div>;
//       })}
//     </div>
//   );
// };

// export default TopThreeGraph;
