import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../../../../newHeader";
import Footer from "../../../../../../newFooter";
import HeaderTabs from "../../../../../../headerTabs";
import InstanceStats from "../../../../instanceStats";
import { Button, Col, Container, Row } from "react-bootstrap";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import ActivityAnalyticsFilter from "../../../../activityAnalyticsFilter";
import PerformanceAnalyticsFilter from "../../../../programAnalyticsFilter";
import PopularProgramApiCall from "../../../../graphs/popularProgram/popularProgramApiCall";
import GradeOfProgramApiCall from "../../../../graphs/gradesOfProgram/gradesOfProgramApiCall";
import ActiveInactiveUserApiCall from "../../../../graphs/activeInactiveUser/activeInActiveUserApiCall";
import CompletionOfProgramApiCall from "../../../../graphs/completionOfProgram/completionOfProgramApiCall";
import MostVisitedActivityApiCall from "../../../../graphs/mostVisitedActivity/mostVisitedActivityApiCall";
import ActivityUnderProgramApiCall from "../../../../graphs/activityUnderProgram/activityUnderProgramApiCall";

type Props = {
  commonProps: {
    status: any;
    programId: any;
    permission: any;
    instituteId: any;
    getProgramId: any;
    setProgramId: any;
    departmentId: any;
    programStatus: any;
    getDepartmentId: any;
    getProgramStatus: any;
  };
};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Header />
      <HeaderTabs activeTab="performance" />
      <BreadcrumbComponent
        routes={props.commonProps.permission ? [
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance (Institute Admin)", path: "" }
        ]: [{name: "Performance (Institute Admin)", path: ""}]}
      />
      <Container fluid className="mt-3">
        <InstanceStats />
      </Container>

      <PerformanceAnalyticsFilter
        instituteId={props.commonProps.instituteId}
        departmentId={props.commonProps.departmentId}
        programStatus={props.commonProps.programStatus}
        getDepartmentId={props.commonProps.getDepartmentId}
        getProgramStatus={props.commonProps.getProgramStatus}
      />
      <div style={{ backgroundColor: "#F3F7FA" }} className="py-4">
        <Container fluid>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h4>{t('performance.program_analytics')}</h4>
          </div>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <PopularProgramApiCall
                status={props.commonProps.programStatus}
                departmentId={props.commonProps.departmentId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <GradeOfProgramApiCall
                status={props.commonProps.programStatus}
                departmentId={props.commonProps.departmentId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <CompletionOfProgramApiCall
                status={props.commonProps.programStatus}
                departmentId={props.commonProps.departmentId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-4">
            <Link to="/performance/details">
              <Button variant="primary">{t('common.view_details')}</Button>
            </Link>
          </div>
        </Container>
      </div>
      <div className="py-4">
        <Container fluid>
          <ActivityAnalyticsFilter
            instituteId={props.commonProps.instituteId}
            getProgramId={props.commonProps.getProgramId}
            programId={props.commonProps.programId}
            setProgramId={props.commonProps.setProgramId}
            departmentId={props.commonProps.departmentId}
          />
          <Row>
            <Col lg={4} md={6} sm={12}>
              <MostVisitedActivityApiCall
                programId={props.commonProps.programId}
                departmentId={props.commonProps.departmentId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ActiveInactiveUserApiCall
                programId={props.commonProps.programId}
                departmentId={props.commonProps.departmentId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <ActivityUnderProgramApiCall
                programId={props.commonProps.programId}
                departmentId={props.commonProps.departmentId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Browser;
