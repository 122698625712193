import React from 'react'
import { Link } from 'react-router-dom'
import { useTranslation } from 'react-i18next';
import Header from '../../../../../../newHeader'
import Footer from '../../../../../../newFooter'
import HeaderTabs from '../../../../../../headerTabs'
import { Button, Col, Container, Row } from 'react-bootstrap'
import PageTitle from '../../../../../../../widgets/pageTitle'
import ActivityAnalyticsFilter from '../../../../activityAnalyticsFilter'
import ProgramAnalyticsFilter from '../../../../programAnalyticsFilter';
import BreadcrumbComponent from '../../../../../../../widgets/breadcrumb';
import NumberOfProgramIndex from '../../instanceAnalyicsComponents/numberOfProgramIndex'
import NumberOfStudentsIndex from '../../instanceAnalyicsComponents/numberOfStudentsIndex'
import PopularProgramApiCall from '../../../../graphs/popularProgram/popularProgramApiCall'
import GradeOfProgramApiCall from '../../../../graphs/gradesOfProgram/gradesOfProgramApiCall'
import PerformanceDetailsIndex from '../../instanceAnalyicsComponents/performanceDetailsIndex'
import ActiveInactiveUserApiCall from '../../../../graphs/activeInactiveUser/activeInActiveUserApiCall'
import CompletionOfProgramApiCall from '../../../../graphs/completionOfProgram/completionOfProgramApiCall'
import MostVisitedActivityApiCall from '../../../../graphs/mostVisitedActivity/mostVisitedActivityApiCall'
import ActivityUnderProgramApiCall from '../../../../graphs/activityUnderProgram/activityUnderProgramApiCall'

type Props = {
  commonProps: {
    programId: any;
    permission: any;
    instituteId: any;
    departmentId: any;
    getProgramId: any;
    setProgramId: any;
    programStatus: any;
    getDepartmentId: any;
    getProgramStatus: any;
  }
}

const Browser = (props: Props) => {
  const { t } = useTranslation();

  return (
    <React.Fragment>
      <Header />
      <HeaderTabs activeTab="performance" />
      <BreadcrumbComponent
        routes={!props.commonProps.permission ? [
          { name: t('common.dashboard'), path: "/dashboard" },
          { name: t('common.performance'), path: "" },
        ] : [{ name: t('common.performance'), path: "" }]}
      />
      <div className="mt-3">
        <div className="mt-3 mb-4">
          <Container fluid>
            <div
              className="mb-4"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <h4>{t('performance.instance_analytics')}</h4>
            </div>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <NumberOfProgramIndex />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <NumberOfStudentsIndex />
              </Col>
              <Col lg={4} md={12} sm={12}>
                <PerformanceDetailsIndex />
              </Col>
            </Row>
          </Container>
        </div>
        <ProgramAnalyticsFilter
          instituteId={props.commonProps.instituteId}
          departmentId={props.commonProps.departmentId}
          programStatus={props.commonProps.programStatus}
          getDepartmentId={props.commonProps.getDepartmentId}
          getProgramStatus={props.commonProps.getProgramStatus}
        />
        <div style={{ backgroundColor: "#F3F7FA" }} className="py-4">
          <Container fluid>
            <div className="mb-4 d-flex justify-content-between align-items-center">
              <h4>{t('performance.program_analytics')}</h4>
            </div>
            <Row>
              <Col lg={4} md={6} sm={12}>
                <PopularProgramApiCall
                  status={props.commonProps.programStatus}
                  instituteId={props.commonProps.instituteId}
                  departmentId={props.commonProps.departmentId}
                />
              </Col>
              <Col lg={4} md={6} sm={12}>
                <GradeOfProgramApiCall
                  status={props.commonProps.programStatus}
                  instituteId={props.commonProps.instituteId}
                  departmentId={props.commonProps.departmentId}
                />
              </Col>
              <Col lg={4} md={12} sm={12}>
                <CompletionOfProgramApiCall
                  status={props.commonProps.programStatus}
                  instituteId={props.commonProps.instituteId}
                  departmentId={props.commonProps.departmentId}
                />
              </Col>
            </Row>
            <div className="d-flex justify-content-end mt-4">
              <Link to="/performance/details">
                <Button variant="primary">{t('common.view_details')}</Button>
              </Link>
            </div>
          </Container>
        </div>
      </div>
      <div className="py-4">
        <Container fluid>
          <ActivityAnalyticsFilter
            programId={props.commonProps.programId}
            instituteId={props.commonProps.instituteId}
            setProgramId={props.commonProps.setProgramId}
            departmentId={props.commonProps.departmentId}
            getProgramId={props.commonProps.getProgramId}
          />
          <Row>
            <Col lg={4} md={6} sm={12}>
              <MostVisitedActivityApiCall
                programId={props.commonProps.programId}
                instituteId={props.commonProps.instituteId}
                departmentId={props.commonProps.departmentId}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ActiveInactiveUserApiCall
               programId={props.commonProps.programId}
               instituteId={props.commonProps.instituteId}
               departmentId={props.commonProps.departmentId}
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <ActivityUnderProgramApiCall
                programId={props.commonProps.programId}
                instituteId={props.commonProps.instituteId}
                departmentId={props.commonProps.departmentId}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  )
}

export default Browser