
import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";
import {
  LineChart,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Line,
} from "recharts";
import { useTranslation } from "react-i18next";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../../globals/globalLazyLoader/routerLadyLoader";

// Helper function to convert total minutes to time string (HH:MM:SS)
const convertMinutesToTime = (totalMinutes: number): string => {
  const hours = Math.floor(totalMinutes / 60);
  const minutes = Math.floor(totalMinutes % 60);
  const seconds = Math.floor((totalMinutes * 60) % 60);
  return `${String(hours).padStart(2, "0")}:${String(minutes).padStart(
    2,
    "0"
  )}:${String(seconds).padStart(2, "0")}`;
};

const CustomTooltip = ({ active, payload }: any) => {
  const {t} = useTranslation();
  if (active && payload) {
    const { name } = payload[0].payload;
    const timespent = payload[0].payload['Time Spent'];
    return (
      <div className="custom-tooltip" style={{ backgroundColor: '#ffffff', padding: '10px', border: '1px solid #cccccc' }}>
        <p className="label" style={{color: "#337ab7"}}>{`${name}`}</p>
        <p className="intro">{t('performance.time_spent')} : <b>{`${convertMinutesToTime(timespent)}`}</b></p>
      </div>
    );
  }
  return null;
};

// Custom XAxis Tick with Tooltip
interface CustomXAxisTickProps {
  x: number;
  y: number;
  payload: { value: string };
  timeSpent: { [key: string]: string };
}

const CustomXAxisTick = ({
  x,
  y,
  payload,
  timeSpent,
}: CustomXAxisTickProps) => {
  const courseName = timeSpent[payload.value];
  return (
    <OverlayTrigger
      placement="top"
      overlay={
        <BSTooltip id={`tooltip-${payload.value}`}>{courseName}</BSTooltip>
      }
    >
      <text x={x} y={y} dy={16} textAnchor="middle" fill="#666">
        {payload.value}
      </text>
    </OverlayTrigger>
  );
};

interface TimeSpentProps {
  error: boolean;
  loading: boolean;
  timeSpentData: Array<{
    avgtimespent: number;
    courseCode: string;
    name: string;
  }>;
}

const TimeSpent = ({ error, loading, timeSpentData }: TimeSpentProps) => {

  const {t} = useTranslation();
  // Define data before using it to create completionCourse
  const dataWithMinutes = timeSpentData.map((timeSpent: any) => {
    return {
      name: timeSpent.name,
      "Time Spent": timeSpent.avgtimespent,
      courseCode: timeSpent.courseCode
    };
  });

  // Create a mapping of courseCode to name
  const timeSpent = timeSpentData.reduce((acc, { courseCode, name }) => {
    acc[courseCode] = name;
    return acc;
  }, {} as { [key: string]: string });

  return (
    <ResponsiveContainer width="100%" height={300}>
      {loading ? (
        <div className="center-loader">
          <RouterLadyLoader status={true} />
        </div>
      ) : error || dataWithMinutes.length === 0 ? (
        <div className="d-flex justify-content-center align-items-center">
          <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
        </div>
      ) : (
        <LineChart
          data={dataWithMinutes}
          margin={{ top: 10, right: 10, left: 32, bottom: 24 }}
        >
          <CartesianGrid strokeDasharray="3 3" vertical={false} />
          <XAxis
            tick={(props) => (
              <CustomXAxisTick {...props} timeSpent={timeSpent} />
            )}
            dataKey="courseCode"
            label={{
              value: t('performance.courses'),
              position: "insideBottom",
              offset: -10,
              dy: 10,
            }}
          />
          <YAxis
            label={{
              value: t('performance.time'),
              angle: -90,
              position: "insideLeft",
              offset: -24,
              dy: 40,
              dx: -2,
            }}
            tickFormatter={convertMinutesToTime}
          />
          <Tooltip
            formatter={(value) => convertMinutesToTime(value as number)}
            content={<CustomTooltip />}
          />
          <Line
            type="linear"
            dataKey="Time Spent"
            stroke="#0254d8"
            strokeWidth={2}
          />
        </LineChart>
      )}
    </ResponsiveContainer>
  );
};

export default TimeSpent;
