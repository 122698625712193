import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";
import Popover from "react-bootstrap/Popover";
import { useTranslation } from "react-i18next";
import { Button, OverlayTrigger } from "react-bootstrap";
import languageswitch from "../assets/images/icons/language-switch.svg";

type Props = {};

const LanguageGlobalFilter: React.FC<Props> = () => {
  const { t, i18n } = useTranslation();
  
  // Load languages from localStorage, use empty object as default
  const [languages, setLanguages] = useState<Record<string, string>>(
    JSON.parse(localStorage.getItem('languages') || "{}")
  );

  // Load selected language from localStorage, default to empty string
  const [selectedValue, setSelectedValue] = useState<string>(
    JSON.parse(localStorage.getItem('selectedLanguages') || '""')
  );

  useEffect(() => {
    // Update localStorage when selectedValue changes
    localStorage.setItem("selectedLanguages", JSON.stringify(selectedValue));
    i18n.changeLanguage(selectedValue); // Function to switch between languages
  }, [selectedValue]);

  useEffect(() => {
    // Update the selectedValue when localStorage changes (for initial load)
    setSelectedValue(JSON.parse(localStorage.getItem('selectedLanguages') || '""'));
  }, []);

  const selectHandler = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value;
    setSelectedValue(value);
  };

  return (
    <div className="switch-role">
      <OverlayTrigger
        trigger={["click"]}
        placement="bottom"
        overlay={
          <Popover id="popover-basic">
            <Popover.Header as="h3">{t('Switch Language')}</Popover.Header>
            <Popover.Body>
              <select
                className="form-select"
                value={selectedValue}
                onChange={selectHandler}
              >
                {Object.entries(languages).map(([key, value]) => (
                  <option key={key} value={key}>
                    {value}
                  </option>
                ))}
              </select>
            </Popover.Body>
          </Popover>
        }
        rootClose
      >
        <Button
          variant="link"
          className="head-icon my-lang-element"
          data-tooltip-variant="light"
          title={t('Switch Languages')}
        >
          <img src={languageswitch} alt={t('Switch Language')} />
        </Button>
      </OverlayTrigger>
      <Tooltip
        anchorSelect=".my-lang-element"
        content={t('Switch Languages')}
        place="bottom"
        style={{ zIndex: 2 }}
      />
    </div>
  );
};

export default LanguageGlobalFilter;
