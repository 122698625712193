import View from "./view";
import { useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { getData } from "../../../../../../adapters/microservices";

const PerformanceCourseDetails = () => {
  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: 30,
  });

  // get current user Role wise id
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  // Get current institute id
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const [programId, setProgramId] = useState(0);
  const [apiStatus, setApiStatus] = useState("");
  const [programStatus, setProgramStatus] = useState("");
  const [courseData, setCourseData] = useState<any[]>([]);
  const [courseDataLength, setCourseDataLength] = useState([]);

  // get view-details Program API call === >>>
  useEffect(() => {
    if (currentUserRole.id > 0 && currentUserRole.id && programId) {
      setApiStatus("started");
      getData(
        `/performance/${currentInstitute}/${currentUserRole.id}/${programId}/course-analytics`,
        {
          ...filterUpdate,
          ...(programId && { programId: programId }),
          ...(programStatus && { status: programStatus }),
        }
      )
        .then((res: any) => {
          if (res.data !== "" && res.status === 200) {
            const proData = res.data.items.flatMap(
              (sem: any) => sem.courseAnalyticsDtos
            );
            setCourseDataLength(proData);
            setCourseData(res.data.items);
          }
          setApiStatus("finished");
        })
        .catch((err: any) => {
          console.log(err);
          setApiStatus("finished");
        });
    }
  }, [currentUserRole.id, programId, programStatus]);

  // get department id Handler
  const getProgramIdHandler = (id: number) => {
    setProgramId(id);
  };

  // get program status Handler
  const programStatusHandler = (status: string) => {
    setProgramStatus(status);
  };

  return (
    <React.Fragment>
      <View
        programId={programId}
        apiStatus={apiStatus}
        courseData={courseData}
        programStatus={programStatus}
        getProgramId={getProgramIdHandler}
        courseDataLength={courseDataLength}
        getProgramStatus={programStatusHandler}
      />
    </React.Fragment>
  );
};

export default PerformanceCourseDetails;
