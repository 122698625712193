import "../../../style.scss";
import "../../../mobileStyle.scss";
import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from "react";
import PerformanceCard from "../../../cardComponent/performanceCard";
import { customPagination, getTotalPagesCount } from "../../../pagination";
import InstituteBarchart from "../../../instanceAnalytics/InstituteBarchart";
import useApiGetMethodHook from "../../../../../../features/customHooks/apiGetMethodHook";

const NumberOfProgramIndex = () => {

  const { t } = useTranslation();

  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: customPagination.PERPAGE,
  });
  
  // call api to get institute-wise-programs === >>
  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(`/performance/institute-wise-programs`, filterUpdate);

  useEffect(()=> {
    refetch()
  }, [filterUpdate])
  // Calculate total number of pages using customPagination
  const totalPages = getTotalPagesCount(apiData.length);

  // Function to update the page number
  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate({ ...filterUpdate, pageNumber: pageRequest });
  };

  // Increment handler
  const handlerIncrement = () => {
    if (filterUpdate.pageNumber < totalPages - 1) {
      newPageRequest(filterUpdate.pageNumber + 1);
    }
  };

  // Decrement handler
  const handlerDecrement = () => {
    if (filterUpdate.pageNumber > 0) {
      newPageRequest(filterUpdate.pageNumber - 1);
    }
  };

    // Calculate the posts to be displayed on the current page
    const currentPosts = apiData.slice(
      filterUpdate.pageNumber * filterUpdate.pageSize,
      filterUpdate.pageNumber * filterUpdate.pageSize + filterUpdate.pageSize
    );

  // Get current page number from filterUpdate
  const currentPage = filterUpdate.pageNumber;

  return (
    <React.Fragment>
      <PerformanceCard
        title={t('performance.no_of_programs')}
        subtitle={t('performance.created_by-institute')}
        handlerIncrement={handlerIncrement}
        handlerDecrement={handlerDecrement}
        totalPages={totalPages}
        currentPage={currentPage}
      >
        <InstituteBarchart data={currentPosts} loading={loading} error={error} />
      </PerformanceCard>
    </React.Fragment>
  );
};

export default NumberOfProgramIndex;
