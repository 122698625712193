import React from 'react'
import Mobile from './mobile'
import Browser from './browser'
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
        instituteId: any
        permission: any
}

const View = (props: Props) => {

    const commonProps = {
        instituteId: props.instituteId,
        permission: props.permission
    }

  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  )
}

export default View