import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Header from "../../../../../../newHeader";
import Footer from "../../../../../../newFooter";
import HeaderTabs from "../../../../../../headerTabs";
import { Button, Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../../../../../../widgets/pageTitle";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import PopularProgramApiCall from "../../../../graphs/popularProgram/popularProgramApiCall";
import GradeOfProgramApiCall from "../../../../graphs/gradesOfProgram/gradesOfProgramApiCall";
import ActiveInactiveUserApiCall from "../../../../graphs/activeInactiveUser/activeInActiveUserApiCall";
import CompletionOfProgramApiCall from "../../../../graphs/completionOfProgram/completionOfProgramApiCall";
import MostVisitedActivityApiCall from "../../../../graphs/mostVisitedActivity/mostVisitedActivityApiCall";
import ActivityUnderProgramApiCall from "../../../../graphs/activityUnderProgram/activityUnderProgramApiCall";
import InstanceStats from "../../../../instanceStats";

type Props = {
  commonProps: {
    instituteId: any;
    permission: any
  };
};

const Browser = (props: Props) => {
  const {t} = useTranslation();
  return (
    <React.Fragment>
      <Header />
      <HeaderTabs activeTab="performance" />
      <BreadcrumbComponent
        routes={!props.commonProps.permission ? [
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance (Department Head)", path: "" },
        ]: [{name: "Performance (Department Head)", path: ""}]}
      />
      <Container fluid className="mt-3">
        <InstanceStats />
      </Container>

      <div style={{ backgroundColor: "#F3F7FA" }} className="py-4">
        <Container fluid>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h4>{t('performance.program_analytics')}</h4>
          </div>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <PopularProgramApiCall
                instituteId={props.commonProps.instituteId}
              />
            </Col>

            <Col lg={4} md={6} sm={12}>
              <GradeOfProgramApiCall
                instituteId={props.commonProps.instituteId}
              />
            </Col>

            <Col lg={4} md={12} sm={12}>
              <CompletionOfProgramApiCall
                instituteId={props.commonProps.instituteId}
              />
            </Col>
          </Row>
          <div className="d-flex justify-content-end mt-4">
            <Link to="/performance/details">
              <Button variant="primary">{t('common.view_details')}</Button>
            </Link>
          </div>
        </Container>
      </div>

      <div className="py-4">
        <Container fluid>
          <div className="mb-4 d-flex justify-content-between align-items-center">
            <h4>{t('performance.activity_analytics')}</h4>
          </div>
          <Row>
            <Col lg={4} md={6} sm={12}>
              <MostVisitedActivityApiCall
                // programId={programId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
            <Col lg={4} md={6} sm={12}>
              <ActiveInactiveUserApiCall
                //    programId={programId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
            <Col lg={4} md={12} sm={12}>
              <ActivityUnderProgramApiCall
                // programId={programId}
                instituteId={props.commonProps.instituteId}
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default Browser;
