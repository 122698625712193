import React from 'react'
import Mobile from './mobile'
import Browser from './browser'
import { isMobile, isDesktop } from "react-device-detect";

type Props = {
  programId: any; 
  permission: any;
  instituteId: any; 
  departmentId: any; 
  getProgramId: any; 
  setProgramId: any; 
  programStatus: any; 
  getDepartmentId: any;
  getProgramStatus: any; 
}

const View = (props: Props) => {

  const commonProps = {
    programId: props.programId,
    permission: props.permission,
    instituteId: props.instituteId,
    departmentId: props.departmentId,
    getProgramId: props.getProgramId,
    setProgramId: props.setProgramId,
    programStatus: props.programStatus,
    getDepartmentId: props.getDepartmentId,
    getProgramStatus: props.getProgramStatus,
  }

  return (
    <React.Fragment>
      {isMobile ? (
        <Mobile commonProps={commonProps} />
      ) : isDesktop ? (
        <Browser commonProps={commonProps} />
      ) : (
        <Browser commonProps={commonProps} />
      )}
    </React.Fragment>
  )
}

export default View