import "../../style.scss";
import View from "./view";
import "../../mobileStyle.scss";
import { useState } from "react";
import { useSelector } from "react-redux";

const SuperAdminPerformance = () => {
  const [programId, setProgramId] = useState(0);
  const [departmentId, setDepartmentId] = useState(0);
  const [programStatus, setProgramStatus] = useState("");

  const permission = useSelector(
    (state: any) => state.userAuthorities.permissions.menu
  );
  
  // get departmentId Handler
  const getDepartmentIdHandler = (id: number) => {
    setDepartmentId(id);
  };

    // Get current institute id
    const currentInstitute = useSelector(
      (state: any) => state.globalFilters.currentInstitute
    );

  const getProgramId = (prgId: number) => {
    setProgramId(prgId);
  };

  const getProgramStatusHandler = (proStatus: string) => {
    setProgramStatus(proStatus);
  };

  return (
      <View 
        programId={programId}
        departmentId={departmentId}
        getProgramId={getProgramId}
        setProgramId={setProgramId}
        programStatus={programStatus}
        instituteId={currentInstitute}
        getDepartmentId={getDepartmentIdHandler}
        permission={permission.dashboard.canView} 
        getProgramStatus={getProgramStatusHandler}
      />
  );
};
export default SuperAdminPerformance;
