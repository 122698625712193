import React from "react";
import config from "../../../utils/config";
import { string } from "yup";
import axios from 'axios';
import { redirect } from "react-router-dom";
import { useSelector } from "react-redux";

// * @field userAuth
// * @field userInfo
// * @field login with data as param
// * @field logout

/**
 * Initializing ContextApi with requried fields.
 * @field status
 * @field token
 * @field isLoggedIn
 * @field userInfo
 * @field setUserStatus
 * @field logout
 * @field setUserToken
 * @field setUserInfo
 */
const UserContext = React.createContext({
  status: "",
  token: "",
  isLoggedIn: false,
  userInfo: {fullname: string, userid: string, userpictureurl: string, userissiteadmin: string},
  setUserStatus: (status: number) => {},
  logout: () => {},
  setUserToken: (token: any) => {},
  setUserInfo: (data: any) => {}
});

const UserInfoData = {
  userid: localStorage.getItem("userid")
    ? localStorage.getItem("userid")
    : null,
  fullname: localStorage.getItem("fullname")
    ? localStorage.getItem("fullname")
    : null,
  userpictureurl: localStorage.getItem("userpictureurl")
    ? localStorage.getItem("userpictureurl")
    : null
};
/**
 * UserContextProvide: Arrow function for UserContext
 * @param props
 */
export const UserContextProvider = (props: { children: any}) => {
  const [token, setToken] = React.useState<any>(
    localStorage.getItem("token") ? localStorage.getItem("token") : null
  );
  const [userInfo, setUserInfo] = React.useState<any>(
    UserInfoData.userid && UserInfoData.fullname && UserInfoData.userpictureurl
      ? UserInfoData
      : UserInfoData
  );
  const [status, setStatus] = React.useState<number>(0);
  // set User logged in detail (boolean)
  const userLoggedIn = !!token;
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const setUserStatus = (data : number) => {
    setStatus(data);
  };

  // set User info
  const setPersonalInfo = (data: any) => {
    setUserInfo(data);
    Object.entries(data.institutes).map(([key, value]: any) => {
      if(key == currentInstitute) {
        localStorage.setItem("userid", value.idNumber);
      }
    });
    localStorage.setItem("fullname", data.first_name + " " + data.last_name);
    localStorage.setItem("userpictureurl", data.files[0]?.url);
  };
  // logout handler
  const logoutHandler = () => {
    setToken(null);
    setStatus(0);
    setUserInfo(null);
    config.WSTOKEN = '';
    localStorage.removeItem("token");
    localStorage.removeItem("loggedIn");
    localStorage.removeItem("fullname");
    localStorage.removeItem("userid");
    localStorage.removeItem("name");
    localStorage.removeItem("userpictureurl");
    localStorage.removeItem("enroled_courses");
    localStorage.clear();

     // Notify other tabs that user logged out
     localStorage.setItem("isLoggedOut", "true");
  };
  // Initializing token
  const setUserToken = (token: any) => {
    let loggedInStatus = (!!token === true) ? 'true' : 'false';
    localStorage.setItem("token", token);
    localStorage.setItem("loggedIn", loggedInStatus);
    setToken(token);
  };

   // Synchronize logout across tabs
   React.useEffect(() => {
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === "isLoggedOut" && event.newValue === "true") {
        // Log out in the current tab as well
        setToken(null);
        setStatus(0);
        setUserInfo(null);
        config.WSTOKEN = '';
        localStorage.removeItem("token");
        localStorage.removeItem("loggedIn");
        localStorage.removeItem("fullname");
        localStorage.removeItem("userid");
        localStorage.removeItem("name");
        localStorage.removeItem("userpictureurl");
        localStorage.removeItem("enroled_courses");
        localStorage.clear();
      }
    };

    // Listen for the storage event
    window.addEventListener("storage", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  // context values
  const contextValues = {
    status: status,
    token: token,
    userInfo: userInfo,
    isLoggedIn: userLoggedIn,
    setUserStatus: setUserStatus,
    logout: logoutHandler,
    setUserToken: setUserToken,
    setUserInfo: setPersonalInfo
  };
  return (
    <UserContext.Provider value={contextValues}>
      {props.children}
    </UserContext.Provider>
  );
};
export default UserContext;
