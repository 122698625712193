
import "../../style.scss";
import "../../mobileStyle.scss";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import PopularProgram from "./popularProgram";
import { useTranslation } from "react-i18next";
import PerformanceCard from "../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const PopularProgramApiCall = ({ instituteId, departmentId, status }: any) => {
  const {t} = useTranslation();
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  // call api to get popular-programs === >>
  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${currentUserRole.id}/${instituteId}/popular-programs`,
    {
      ...{ pageNumber: 0, pageSize: 5 },
      ...(status && { status: status }),
      ...(departmentId && { departmentId: departmentId }),
    }
  );

  useEffect(() => {
    if(instituteId > 0 && currentUserRole.id > 0){ 
    refetch();
    }
  }, [departmentId, status,instituteId, currentUserRole.id]);

  // console.log(apiData, 'apiData')

  return (
    <React.Fragment>
      <PerformanceCard title={t('performance.top')} subtitle={t('performance.popular_program')}>
        <PopularProgram
          error={error}
          loading={loading}
          proData={apiData?.items || []}
        />
      </PerformanceCard>
    </React.Fragment>
  );
};

export default PopularProgramApiCall;
