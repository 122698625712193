import "../../style.scss";
import "../../mobileStyle.scss";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import MostVisitedActivity from "./mostVisitedActivity";
import PerformanceCard from "../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const MostVisitedActivityApiCall = ({ instituteId,departmentId, programId }: any) => {

  const {t} = useTranslation();
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  // call api to get popular-programs === >>
  const { apiData, loading,apiStatus, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${currentUserRole.id}/${instituteId}/most-visited-activity`,
    {
      ...(programId && { programId: programId }),
      ...(departmentId && { departmentId: departmentId }),
    }
  );

  useEffect(() => {
    if(currentUserRole.shortName === "editingteacher"){
      if (instituteId > 0 && currentUserRole.id && programId > 0) {
        refetch();
      }
    }else{
      if (instituteId > 0 && currentUserRole.id) {
        refetch();
      }
    }
  }, [programId,departmentId, currentUserRole.id, instituteId]);

  return (
    <React.Fragment>
      <PerformanceCard 
        mostVisited="mostVisited"
        title={t('performance.top')} 
        subtitle={t('performance.mostVisiteActivity')} 
        >
        <MostVisitedActivity
          error={error}
          loading={loading}
          apiStatus={apiStatus}
          mostVisitData={apiData || []}
        />
      </PerformanceCard>
    </React.Fragment>
  );
};

export default MostVisitedActivityApiCall;
