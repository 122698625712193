import React from 'react';
import defaultProfile from "../../../../../assets/images/profile.png";
import NoDataFoundImg from "../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from '../../../../../globals/globalLazyLoader/routerLadyLoader';
import { isMobile } from 'react-device-detect';
import { convertTimeToSeconds } from '../../../../../lib/timestampConverter';
import { useTranslation } from 'react-i18next';

interface PeerComparisonData {
    firstName: string;
    lastName: string;
    completion: number;
    grade: number;
    timespentInString: string;
    rank: number;
    userId: number;
    fileId: number | null;
    profilePicUrl: string;
}

interface PeersRankingProps {
    peerComparisonData: PeerComparisonData[];
    apiStatus: string;
}

const PeersRanking: React.FC<PeersRankingProps> = ({ peerComparisonData, apiStatus }) => {
    const {t} = useTranslation();
    const userid = JSON.parse(localStorage.getItem("userInfo") || "{}").userInfo?.uid;

    if (apiStatus === "started") {
        return (
            <div className="center-loader">
                <RouterLadyLoader status={true} />
            </div>
        );
    }

    // if (apiStatus === "finished") {
    //     // Filter and sort the data
        // const filteredData = peerComparisonData.filter(item =>
        //     (item.rank > 3 && parseFloat(item.completion) > 0 && parseFloat(item.grade) > 0) ||
        //     item.userId === userid
        // ).sort((a, b) => a.rank - b.rank);

    //     // Check if there are items with rank > 3
    //     const hasRankedPeers = filteredData.some(item => item.rank > 3);

    //     if (!hasRankedPeers) {
    //         return (
    //             <div className="d-flex justify-content-center align-items-center">
    //                 <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
    //             </div>
    //         );
    //     }
    if (peerComparisonData.length < 3) {
        return (
            <div className="d-flex justify-content-center align-items-center">
                <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
            </div>
        );}

        return (
            <>
                {peerComparisonData.map((data) => {
                    if (data.rank > 3) {
                        return (
                            <div
                                key={data.userId}
                                className={`${isMobile ? 'd-flex gap-2 align-items-center peer-user mb-2' : 'd-flex gap-3 align-items-center peer-user mb-2'} ${data.userId === userid ? 'active' : ''}`}
                            >
                                {(+data.completion > 0 || +data.grade > 0 || convertTimeToSeconds(data.timespentInString)) ? <span className='rank'>{data.rank}</span>: <span className='rank'>{""}</span>}
                                <img
                                    src={data.profilePicUrl || defaultProfile}
                                    className='rounded-circle'
                                    alt={`${data.firstName} ${data.lastName}`}
                                />
                                <div>
                                    <h6 className='name'>{`${data.firstName} ${data.lastName}`}</h6>
                                    <ul className={`${isMobile ? 'list-inline m-0 d-flex flex-column' : 'list-inline m-0 d-flex gap-2'}`}>
                                        <li className='list-inline-item me-0'>
                                            <span>{t('performance.completions')}</span>: {data.completion ?? 0}%
                                        </li>
                                        <li>/ <span>{t('performance.grade')}</span>: {data.grade ?? 0}%</li>
                                        <li>/ <span>{t('performance.time_spent')}</span>: {data.timespentInString}</li>
                                    </ul>
                                </div>
                                {data.userId === userid && <span className='badge text-bg-primary ms-auto ranking-user'>{t('performance.you')}</span>}
                            </div>
                        );
                    }
                    return null;
                })}
            </>
        );
    }

//     return null;
// }

export default PeersRanking;

