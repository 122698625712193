
import {
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  BarChart,
  Rectangle,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import "../../../style.scss";
import "../../../mobileStyle.scss";
import { useTranslation } from "react-i18next";
import { OverlayTrigger, Tooltip as BSTooltip } from "react-bootstrap";
import NoDataFoundImg from "../../../../../../assets/images/no-data-found.png";
import RouterLadyLoader from "../../../../../../globals/globalLazyLoader/routerLadyLoader";

// Custom Bar Shape
const CustomBarShape = (props) => {
  const { fill, x, y, width, height } = props;
  return (
    <Rectangle
      x={x}
      y={y}
      width={width}
      height={height}
      fill={fill}
      radius={[6, 6, 0, 0]}
    />
  );
};

 // Format date for the x-axis labels
 const formatDate = (dateString: string) => {
    const options: Intl.DateTimeFormatOptions = {
      day: "2-digit",
      month: "short",
    };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

const ActivityCompleted = ({ activityData = [], error, loading }: any) => {
  const {t} = useTranslation();
  // Define data before using it to create completionProgram
  const data = activityData.map((program: any) => {
    return {
        visitDate: formatDate(program.visitDate),
      "Activity Count": program.activityCount,

    };
  });

  return (
    <div className="chart-container">
      {loading ? (
        <div className="center-loader">
          <RouterLadyLoader status={true} />
        </div>
      ) : error || data.length === 0 ? (
        <div className="d-flex justify-content-center align-center">
          <img className="nodata-img" alt="No Data" src={NoDataFoundImg} />
        </div>
      ) : (
        <ResponsiveContainer width="100%" height={300}>
          <BarChart
            width={440}
            height={300}
            data={data}
            margin={{ top: 5, right: 10, left: 0, bottom: 24 }}
          >
            <CartesianGrid strokeDasharray="3 3" vertical={false} />
            <XAxis
              dataKey="visitDate"
              label={{
                value: t('common.date'),
                position: "insideBottom",
                offset: -10,
                dy: 10,
              }}
            />
             <YAxis
                label={{
                  value: t('performance.no_of_activity'),
                  angle: -90,
                  position: "insideLeft",
                  offset: 0,
                  dy: 60,
                  dx: 10,
                }}
              />
            <Tooltip />
            <Bar
              dataKey="Activity Count"
              fill="#0A68C5"
              shape={<CustomBarShape />}
              barSize={22}
            />
          </BarChart>
        </ResponsiveContainer>
      )}
    </div>
  );
};

export default ActivityCompleted;