import React from "react";
import Filter from "../../filter";
import { Link } from "react-router-dom";
import { Button, Col, Container, Row } from "react-bootstrap";
import PageTitle from "../../../../../../../widgets/pageTitle";
import MobileHeader from "../../../../../../newHeader/mobileHeader";
import MobileFooter from "../../../../../../newFooter/mobileFooter";
import BreadcrumbComponent from "../../../../../../../widgets/breadcrumb";
import TimeSpentApiCall from "../../../../graphs/timeSpent/timeSpentApiCall";
import ActivityCompletedApiCall from "../../activityCompleted/activityCompletedApiCall";
import GradeOfCourseApiCall from "../../../../graphs/gradeOfCourse/gradeOfCourseApiCall";
import CompletionOfCourseApiCall from "../../../../graphs/completionOfCourse/completionOfCourseApiCall";
import ActiveInactiveUserApiCall from "../../../../graphs/activeInactiveUser/activeInActiveUserApiCall";
import MostVisitedActivityApiCall from "../../../../graphs/mostVisitedActivity/mostVisitedActivityApiCall";
type Props = {
  commonProps: {
    programId: any;
    instituteId: any;
    getProgramId: any;
  };
};

const Mobile = (props: Props) => {
  return (
    <React.Fragment>
      <MobileHeader />
      <BreadcrumbComponent
        routes={[
          { name: "Dashboard", path: "/dashboard" },
          { name: "Performance (Teacher)", path: "" },
        ]}
      />
      <div className="contentarea-wrapper mb-wraper">
        <div className="contentarea-wrapper mt-3 mb-5">
          <Container fluid className="mt-3">
            <PageTitle pageTitle="Performance" gobacklink="/dashboard" />
          </Container>

          <div style={{ backgroundColor: "#F3F7FA" }} className="py-4">
            <Container fluid>
              <Filter
                programId={props.commonProps.programId}
                getProgramId={props.commonProps.getProgramId}
              />
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <GradeOfCourseApiCall
                    programId={props.commonProps.programId}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <CompletionOfCourseApiCall
                    programId={props.commonProps.programId}
                  />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <MostVisitedActivityApiCall
                    programId={props.commonProps.programId}
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>
              </Row>
            </Container>
          </div>

          <div className="py-4">
            <Container fluid>
              <div className="mb-4 d-flex justify-content-between align-items-center">
                <h4>Activity Analytics</h4>
              </div>
              <Row>
                <Col lg={4} md={6} sm={12}>
                  <ActivityCompletedApiCall
                    programId={props.commonProps.programId}
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>
                <Col lg={4} md={6} sm={12}>
                  <TimeSpentApiCall programId={props.commonProps.programId} />
                </Col>
                <Col lg={4} md={12} sm={12}>
                  <ActiveInactiveUserApiCall
                    programId={props.commonProps.programId}
                    instituteId={props.commonProps.instituteId}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end mt-4">
                <Link
                  to={`/performance/course/details/${props.commonProps.programId}`}
                >
                  <Button variant="primary">View Details</Button>
                </Link>
              </div>
            </Container>
          </div>
        </div>
      </div>
      <MobileFooter />
    </React.Fragment>
  );
};

export default Mobile;
