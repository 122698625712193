
import "../../style.scss";
import "../../mobileStyle.scss";
import { useSelector } from "react-redux";
import { Col, Row } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { getData } from "../../../../../adapters/microservices";

const Filter = ({
  getProgramId,
  programId,
}: any) => {
  const {t} = useTranslation();
  const [programData, setProgramData] = useState<any>([]);

  // Get current Role id
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  useEffect(() => {
    if (currentUserRole.id > 0) {
      getData(`/${currentUserRole.id}/dashboard`, {})
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setProgramData(result.data.programs);

            const defaultProgram = result.data.programs[0]
            if (defaultProgram) {
                getProgramId(defaultProgram.id);
            }
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [currentUserRole.id]);

  const getCurrentProgram = (e: any) => {
    const progId = parseInt(e.target.value);
    getProgramId(progId);

    // Reset the status when program changes
    // getProgramStatus("");
  };

  return (
    <React.Fragment>
      <div className="mb-4 d-flex justify-content-between align-items-center">
        <h4>{t('performance.peers_comparision')}</h4>
        <Row>
          <Col>
            <label>{t('common.program')}</label>
            <select
              className="form-select program-select"
              name="program"
              onChange={getCurrentProgram}
              value={programId}
            >
              {programData &&
                programData.map(
                  (option: any) =>
                    option.published && (
                      <option key={option.id} value={option.id}>
                        {option.name}
                      </option>
                    )
                )}
            </select>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

export default Filter;
