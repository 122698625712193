import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import UserContext from "../../features/context/user/user";

type Props = {};

const Logout = (props: Props) => {
  const userCtx = useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    // Call the logout function in the context
    userCtx.logout();
    
    // Redirect to the homepage or login screen
    navigate("/");
  }, []);

  return null;
};

export default Logout;
