
import "../style.scss";
import React from "react";
import "../mobileStyle.scss";
import { Table } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Errordiv from "../../../../widgets/alert/errordiv";
import TableSkeleton from "../../../../widgets/skeleton/table";

const CoursePerformanceTable = ({ apiStatus, courseData, courseDataLength }: any) => {
  let serialNumber = 1;

  const {t} = useTranslation();

  return (
    <React.Fragment>
      <div className="table-responsive admin-table-wrapper mt-3">
        <Table bordered striped responsive >
          <thead>
            <tr>
              <th>{t('common.s_no')}</th>
              <th>{t('performance.course_name')}</th>
              <th>{t('performance.years_sem_block')}</th>
              <th>{t('performance.enrolled_users')}</th>
              <th>{t('performance.completed')}</th>
              <th>{t('performance.in_progress')}</th>
              <th>{t('performance.not_started')}</th>
              <th>{t('performance.total_activities')}</th>
              <th>{t('performance.avg_completion')}</th>
              <th>{t('performance.avg_grade')}</th>
              <th>{t('performance.total_time_spent')}</th>
              <th>{t('performance.avg_time_spent')}</th>
            </tr>
          </thead>
          <tbody>
          {Array.isArray(courseData) && courseData.length > 0 && (
              courseData.map((semester: any, semesterIndex: number) => (
              <React.Fragment key={semesterIndex}>
               {Array.isArray(semester.courseAnalyticsDtos) && semester.courseAnalyticsDtos.map(
                  (course: any, courseIndex: number) => (
                    <tr key={`${semesterIndex}_${courseIndex}`}>
                      {courseIndex === 0 && (
                        <td rowSpan={semester.courseAnalyticsDtos.length}>
                          {serialNumber++}
                        </td>
                      )}
                      {courseIndex === 0 && (
                        <td rowSpan={semester.courseAnalyticsDtos.length}>
                          {semester.name}
                        </td>
                      )}
                      <td>{course.name}</td>
                      <td>{course.enrolledUsers}</td>
                      <td>{course.completed}</td>
                      <td>{course.inProgress}</td>
                      <td>{course.notStarted}</td>
                      <td>{course.totalActivities !== null ? course.totalActivities : "-"}</td>
                     <td>{course.avgCompletion}</td>
                      <td>{course.avgGrade}</td> 
                      <td>{course.totalTimeSpent}</td>
                      <td>{course.avgTimeSpent}</td> 
                    </tr>
                  )
                )}
              </React.Fragment>
            )))}
          </tbody>
        </Table>
        {apiStatus === "started" && courseDataLength.length === 0 && (
          <TableSkeleton numberOfRows={5} numberOfColumns={4} />
        )}
        {apiStatus === "finished" && courseDataLength.length === 0 && (
          <Errordiv msg="No record found!" cstate className="mt-2" />
        )}
      </div>
    </React.Fragment>
  );
};

export default CoursePerformanceTable;

