import "./style.scss";
import "./mobileStyle.scss";
import { Container } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { pagination } from "../../../utils/pagination";
import { getData } from "../../../adapters/microservices";

const ActivityAnalyticsFilter = ({
  instituteId,
  getProgramId,
  programId,
  departmentId,
  setProgramId,
}: any) => {
  const {t} = useTranslation();
  const [programData, setProgramData] = useState<any>([]);
  const [filterUpdate, setFilterUpdate] = useState<any>({
    pageNumber: 0,
    pageSize: pagination.PERPAGE,
  });

  useEffect(() => {
    if (departmentId > 0) {
      getData(`/${instituteId}/programs`, {
        ...filterUpdate,
        departmentId: departmentId,
      })
        .then((result: any) => {
          if (result.data !== "" && result.status === 200) {
            setProgramData(result.data.items);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      setProgramId(0);
      setProgramData([]);
    }
  }, [filterUpdate, departmentId]);

  const getCurrentProgram = (e: any) => {
    const progId = parseInt(e.target.value);
    getProgramId(progId);
  };

  return (
    <React.Fragment>
      <Container fluid>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <h4>{t('performance.activity_analytics')}</h4>
          <div className="d-flex gap-3">
            <select
              className="form-select"
              name="program"
              onChange={getCurrentProgram}
              value={programId}
              disabled={departmentId === 0}
            >
              <option value={0}>{t('performance.all_programs')}</option>
              {programData &&
                programData.map((option: any) => (
                  option.published &&
                  <option key={option.id} value={option.id}>
                    {option.name}
                  </option>
                ))}
            </select>
          </div>
        </div>
      </Container>
    </React.Fragment>
  );
};

export default ActivityAnalyticsFilter;
