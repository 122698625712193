
import "../../style.scss";
import "../../mobileStyle.scss";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { customPagination } from "../../pagination";
import ProgramCompletion from "./programCompletion";
import PerformanceCard from "../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const CompletionOfProgramApiCall = ({departmentId,status,instituteId}: any) => {
  const {t} = useTranslation();

  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const [filterUpdate, setFilterUpdate] = useState({
    pageNumber: 0,
    pageSize: customPagination.PERPAGE,
  });

  const [completionProgramData, setCompletionProgramData] = useState({
    items: [],
    pager: { totalElements: 0, totalPages: 0 },
  });

  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${currentUserRole.id}/${instituteId}/program-completion`,
    {
      ...filterUpdate,
      ...(status && { status }),
      ...(departmentId && { departmentId }),
    }
  );

  // Trigger the refetch when dependencies change
  useEffect(() => {
    if (instituteId > 0 && currentUserRole.id > 0) {
      refetch();
    }
  }, [departmentId,status,instituteId,currentUserRole.id,filterUpdate.pageNumber]);

  useEffect(() => {
    if (apiData) {
      setCompletionProgramData({
        items: apiData.items || [],
        pager: apiData.pager || { totalElements: 0, totalPages: 0 },
      });
    }
  }, [apiData]);

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate((prevFilterUpdate) => ({
      ...prevFilterUpdate,
      pageNumber: pageRequest,
    }));
  };

  // Reset pagination when departmentId changes
  useEffect(() => {
    setFilterUpdate((prevFilterUpdate) => ({
      ...prevFilterUpdate,
      pageNumber: 0,
    }));
  }, [departmentId]);

  const handlerIncrement = () => {
    if (filterUpdate.pageNumber < completionProgramData.pager.totalPages - 1) {
      newPageRequest(filterUpdate.pageNumber + 1);
    }
  };

  const handlerDecrement = () => {
    if (filterUpdate.pageNumber > 0) {
      newPageRequest(filterUpdate.pageNumber - 1);
    }
  };

  return (
    <PerformanceCard
      title={t('performance.average')}
      subtitle={t('performance.completion_of_program')}
      handlerDecrement={handlerDecrement}
      handlerIncrement={handlerIncrement}
      currentPage={filterUpdate.pageNumber}
      totalPages={completionProgramData.pager.totalPages}
    >
      <ProgramCompletion
        error={error}
        loading={loading}
        completionProgramData={completionProgramData.items}
      />
    </PerformanceCard>
  );
};

export default CompletionOfProgramApiCall;
