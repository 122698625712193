import "../../../style.scss";
import "../../../mobileStyle.scss";
import React, { useEffect } from "react";
import InstanceStats from "../../../instanceAnalytics/instanceStats";
import useApiGetMethodHook from "../../../../../../features/customHooks/apiGetMethodHook";

const PerformanceDetailsIndex = () => {

  // call api to get institutes-details === >>
  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(`/performance/institutes-details`, {});

  useEffect(()=> {
    refetch()
  }, [])

  return (
    <React.Fragment>
      <InstanceStats 
        error={error}
        loading={loading} 
        performanceDetailsData={apiData} 
        />
    </React.Fragment>
  );
};

export default PerformanceDetailsIndex;

