import { useTable } from "react-table";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import config from "../../../../utils/config"
import Errordiv from "../../../../widgets/alert/errordiv";
import TableSkeleton from "../../../../widgets/skeleton/table";
import UserContext from "../../../../features/context/user/user";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Table, OverlayTrigger, Tooltip as BsTooltip } from "react-bootstrap";
const CurriculumTable = ({
  categoryData,
  apiStatus,
}: any) => {

  const userCtx = useContext(UserContext);
  const isLoggedIn = userCtx.isLoggedIn;
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );
  const tableColumn = [
    {
      Header: "Categories",
      accessor: "name",
      Cell: ({ row }: any) => {
        return (
          <div
            style={{
              paddingLeft: setLevelPadding(row.original.level - 1),
            }}
          >
            {row.values.name}
          </div>
        );
      },
    },
    {
      Header: "Courses",
      accessor: "coursename",
      Cell: ({ row }: any) => { 
        return (
          <>
          {isLoggedIn === true && currentUserRole.shortName !== "student" ? row.original.coursename && row.original.coursename !== undefined && row.original.coursedetails.published && (
         
         <OverlayTrigger
            placement="top"
            overlay={<BsTooltip>Course Preview</BsTooltip>}
            >
            <Link
              className="action-icons"
              to={
                row.original.coursedetails?.idNumber && row.original.coursedetails?.idNumber !== undefined
                ? `${config.MOODLE_BASE_URL}/course/view.php?id=${row.original.coursedetails.idNumber}`
                : "#"
              }
              >
              {row.original.coursename}
            </Link>
          </OverlayTrigger>
        ) : 
        <div
        style={{
          paddingLeft: setLevelPadding(row.original.level),
        }}
        >
            
            {row.original.coursename !== undefined && row.original.coursedetails.published && (
              <>{row.original.coursename}</>
              )}
          </div>
            }
              </>
        );
      },
    }
  ];

  const [selectedData, setSelectedData] = useState<any>([]);
  const columns = useMemo(() => tableColumn, []);
  const data = useMemo(() => selectedData, [selectedData]);
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  useEffect(() => {
    setSelectedData(categoryData)
  }, [categoryData]);

  const setLevelPadding = (level : number) => {
    let padding = ((level - 1) * 30) + "px";
      return padding;
  }

  return (
    <React.Fragment>
      <div className="table-responsive table-wrapper mt-3">
          <Table borderless striped {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th {...column.getHeaderProps()} key={index}>
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>

                <tbody
                  {...getTableBodyProps()}
                >
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                          <tr
                            {...row.getRowProps()}
                          >
                            {row.cells.map((cell, index) => (
                              <td
                                {...cell.getCellProps()}
                                key={index}
                              >
                                {cell.render("Cell")}
                              </td>
                            ))}
                          </tr>
                    );
                  })}
                </tbody>
          </Table>
          {apiStatus === "started" && categoryData.length === 0 && (
          <TableSkeleton numberOfRows={5} numberOfColumns={4} />
        )}
        {apiStatus === "finished" && categoryData.length === 0 && (
          <Errordiv msg="No record found!" cstate className="mt-3" />
        )}
      </div>
    </React.Fragment>
  );
};

export default CurriculumTable;
