import "../../style.scss"
import "../../mobileStyle.scss";
import TimeSpent from "./timeSpent";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { customPagination } from "../../pagination";
import PerformanceCard from "../../cardComponent/performanceCard";
import useApiGetMethodHook from "../../../../../features/customHooks/apiGetMethodHook";

const TimeSpentApiCall = ({ programId }: any) => {
  const {t} = useTranslation();
  const currentUserRole = useSelector(
    (state: any) => state.globalFilters.currentUserRole
  );

  const [filterUpdate, setFilterUpdate] = useState({
    pageNumber: 0,
    pageSize: customPagination.PERPAGE,
  });

  // Get current institute id
  const currentInstitute = useSelector(
    (state: any) => state.globalFilters.currentInstitute
  );

  const [timeSpentData, setTimeSpenmtData] = useState({
    items: [],
    pages: { totalElements: 0, totalPages: 0 },
  });

  // call api to get program-average-grade === >>
  const { apiData, loading, error, refetch } = useApiGetMethodHook<any>(
    `/performance/${currentInstitute}/${currentUserRole.id}/${programId}/program-timespent`,
    {
      ...filterUpdate,
      //   ...(status && { status }),
      ...(programId && { programId }),
    }
  );

  // Trigger the refetch when dependencies change
  useEffect(() => {
    if (programId > 0 && currentUserRole.id > 0) {
      refetch();
    }
  }, [programId, currentUserRole.id, filterUpdate.pageNumber]);

  useEffect(() => {
    if (apiData) {
      setTimeSpenmtData({
        items: apiData.items || [],
        pages: apiData.pages || { totalElements: 0, totalPages: 0 },
      });
    }
  }, [apiData]);

  const newPageRequest = (pageRequest: number) => {
    setFilterUpdate((prevFilterUpdate) => ({
      ...prevFilterUpdate,
      pageNumber: pageRequest,
    }));
  };

  // Reset pagination when departmentId changes
  useEffect(() => {
    setFilterUpdate((prevFilterUpdate) => ({
      ...prevFilterUpdate,
      pageNumber: 0,
    }));
  }, [programId]);

  const handlerIncrement = () => {
    if (filterUpdate.pageNumber < timeSpentData.pages.totalPages - 1) {
      newPageRequest(filterUpdate.pageNumber + 1);
    }
  };

  const handlerDecrement = () => {
    if (filterUpdate.pageNumber > 0) {
      newPageRequest(filterUpdate.pageNumber - 1);
    }
  };

  return (
    <React.Fragment>
      <PerformanceCard
        title={t('performance.average')} 
        subtitle={t('performance.time_spent_course')} 
        handlerDecrement={handlerDecrement}
        handlerIncrement={handlerIncrement}
        currentPage={filterUpdate.pageNumber}
        totalPages={timeSpentData.pages.totalPages}
      >
        <TimeSpent 
          error={error}
          loading={loading}
          timeSpentData={timeSpentData.items}
          />
      </PerformanceCard>
    </React.Fragment>
  );
};

export default TimeSpentApiCall;
